export const IMAGE_MIME_TYPES = new Set(['image/jpeg', 'image/png'])
export const DOCUMENT_MIME_TYPES = new Set([
  ...IMAGE_MIME_TYPES,
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
])

export const IMAGE_FILE_EXTENSIONS = ['.jpg', '.jpeg', '.png']
export const DOCUMENT_FILE_EXTENSIONS = [
  ...IMAGE_FILE_EXTENSIONS,
  '.doc',
  '.docx',
  '.pdf',
  '.xls',
  '.xlsx',
]

export const JSON_TO_FORMDATA_OPTIONS = {
  indices: true,
  noFilesWithArrayNotation: true,
  dotsForObjectNotation: true,
  allowEmptyArrays: true,
}
