import { withStyles } from '@bruitt/classnames'
import { keys, omit, pick } from 'rambda'

import { Box, BoxProps } from '../box'

import s from './flex.module.scss'

const sx = withStyles(s)

type Gaps = '2' | '4' | '6' | '8' | '10' | '12' | '16' | '20' | '24' | '40'
type Direction = 'row' | 'row-reverse' | 'column' | 'column-reverse'
type Wrap = 'nowrap' | 'wrap' | 'wrap-reverse'
type JustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
type AlignItems = 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline'
type GrowShrink = '0' | '1' | '2' | '3' | '4'

interface FlexModelProps {
  g?: Gaps
  cg?: Gaps
  rg?: Gaps
  dir?: Direction
  w?: Wrap
  jc?: JustifyContent
  ai?: AlignItems
  // responsive props
  // gap
  g_sm?: Gaps
  g_md?: Gaps
  g_lg?: Gaps
  g_xl?: Gaps
  // column-gap
  cg_sm?: Gaps
  cg_md?: Gaps
  cg_lg?: Gaps
  cg_xl?: Gaps
  // row-gap
  rg_sm?: Gaps
  rg_md?: Gaps
  rg_lg?: Gaps
  rg_xl?: Gaps
  // flex-direction
  dir_sm?: Direction
  dir_md?: Direction
  dir_lg?: Direction
  dir_xl?: Direction
  // flex-wrap
  w_sm?: Wrap
  w_md?: Wrap
  w_lg?: Wrap
  w_xl?: Wrap
  // justify-content
  jc_sm?: JustifyContent
  jc_md?: JustifyContent
  jc_lg?: JustifyContent
  jc_xl?: JustifyContent
  // align-items
  ai_sm?: AlignItems
  ai_md?: AlignItems
  ai_lg?: AlignItems
  ai_xl?: AlignItems
  // flex-grow
  gr_sm?: GrowShrink
  gr_md?: GrowShrink
  gr_lg?: GrowShrink
  gr_xl?: GrowShrink
  // flex-shrink
  sh_sm?: GrowShrink
  sh_md?: GrowShrink
  sh_lg?: GrowShrink
  sh_xl?: GrowShrink
}

type FlexPropsKey = keyof FlexModelProps

const FLEX_PROPS: { [key in FlexPropsKey]: true } = {
  g: true,
  cg: true,
  rg: true,
  dir: true,
  w: true,
  jc: true,
  ai: true,
  g_sm: true,
  g_md: true,
  g_lg: true,
  g_xl: true,
  cg_sm: true,
  cg_md: true,
  cg_lg: true,
  cg_xl: true,
  rg_sm: true,
  rg_md: true,
  rg_lg: true,
  rg_xl: true,
  dir_sm: true,
  dir_md: true,
  dir_lg: true,
  dir_xl: true,
  w_sm: true,
  w_md: true,
  w_lg: true,
  w_xl: true,
  jc_sm: true,
  jc_md: true,
  jc_lg: true,
  jc_xl: true,
  ai_sm: true,
  ai_md: true,
  ai_lg: true,
  ai_xl: true,
  gr_sm: true,
  gr_md: true,
  gr_lg: true,
  gr_xl: true,
  sh_sm: true,
  sh_md: true,
  sh_lg: true,
  sh_xl: true,
}

const FLEX_PROPS_KEYS = keys(FLEX_PROPS)

export interface FlexProps extends FlexModelProps, BoxProps {}

export const Flex = ({
  children,
  className,
  display = 'flex',
  ...props
}: FlexProps) => {
  const flexProps = pick(FLEX_PROPS_KEYS, props)
  const rest = omit(FLEX_PROPS_KEYS, props)

  return (
    <Box className={sx(className, flexProps)} display={display} {...rest}>
      {children}
    </Box>
  )
}
