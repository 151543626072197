interface Person {
  firstName?: string
  middleName?: string | null
  lastName?: string
}

export const getFullName = <T extends Person>(data?: T) => {
  if (!data) return ''

  return [data.lastName, data.firstName, data.middleName]
    .filter(Boolean)
    .join(' ')
}
