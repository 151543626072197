import { forwardRef, useRef } from 'react'
import { PatternFormat, PatternFormatProps } from 'react-number-format'

import { InputWrapper, InputWrapperProps } from '../../uikit/input-wrapper'

interface PhoneInputProps extends Omit<PatternFormatProps, 'format'> {
  placeholder: string
  className?: string
  isError?: InputWrapperProps['isError']
  pre?: InputWrapperProps['pre']
  post?: InputWrapperProps['post']
  s?: InputWrapperProps['s']
  onClick?: InputWrapperProps['onClick']
  name?: string
  isClearable?: boolean
}

export const InputPhone = forwardRef<HTMLInputElement, PhoneInputProps>(
  (props, _) => {
    const {
      pre,
      post,
      s = 'xl',
      isError,
      disabled,
      placeholder,
      onClick,
      ...rest
    } = props

    const nativeInputRef = useRef<HTMLInputElement | null>(null)

    return (
      <InputWrapper
        ref={nativeInputRef}
        pre={pre}
        post={post}
        s={s}
        isError={isError}
        disabled={disabled}
        onClick={onClick}
        placeholder={placeholder}
        isNumberInput
      >
        <PatternFormat
          format={'+# ### ### ####'}
          allowEmptyFormatting={false}
          mask=" "
          placeholder={placeholder}
          disabled={disabled}
          {...rest}
        />
      </InputWrapper>
    )
  },
)

InputPhone.displayName = 'InputPhone'
