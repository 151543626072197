import { withStyles } from '@bruitt/classnames'

import { BaseModal, BaseModalProps } from '../base-modal'

import s from './center-modal.module.scss'

const sx = withStyles(s)

export interface CenterModalProps extends BaseModalProps {
  width?: '312'
}

export const CenterModal = ({
  className,
  overlayClassName,
  width,
  ...rest
}: CenterModalProps) => {
  return (
    <BaseModal
      className={sx(s.root, { width }, className)}
      overlayClassName={sx(s.overlay, overlayClassName)}
      {...rest}
    />
  )
}
