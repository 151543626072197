import { withStyles } from '@bruitt/classnames'
import { forwardRef, ReactNode } from 'react'

import { IconSize } from '../../types/icons'
import { Icon, IconName } from '../icon/icon'
import { StyledButton, StyledButtonProps } from '../styled-button'

import s from './icon-button.module.scss'

const sx = withStyles(s)

type IconButtonSize = 's' | 'm' | 'l' | 'xl'

interface IconButtonWrapperProps extends StyledButtonProps {
  s: IconButtonSize
  children?: ReactNode
}

export const IconButtonWrapper = forwardRef<
  HTMLButtonElement,
  IconButtonWrapperProps
>(({ s, children, ...rest }, ref) => {
  return (
    <StyledButton ref={ref} className={sx('button', { s })} {...rest}>
      {children}
    </StyledButton>
  )
})

interface IconButtonProps extends StyledButtonProps {
  s: IconButtonSize
  icon: IconName
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ s, icon, className, ...rest }, ref) => {
    const iconSize = BUTTON_ICON_SIZE_MAP[s]

    return (
      <StyledButton
        ref={ref}
        className={sx('button', { s }, className)}
        {...rest}
      >
        <Icon name={icon} s={iconSize} />
      </StyledButton>
    )
  },
)

const BUTTON_ICON_SIZE_MAP: { [key in IconButtonSize]: IconSize } = {
  s: '16',
  m: '20',
  l: '24',
  xl: '24',
}

IconButton.displayName = 'IconButton'
IconButtonWrapper.displayName = 'IconButtonWrapper'
