import { AnyRoute, ParseRoute } from '@tanstack/react-router'

import { routeTree } from '../../routeTree.gen'
import { KebabToCamel } from '../types/type-helpers'

type RouteIds<TRouteTree extends AnyRoute> = ParseRoute<TRouteTree>['path']

type WithLeadingSlash<T extends string> = T extends `/${string}` ? T : `/${T}`

export type Pathname = WithLeadingSlash<Exclude<RouteIds<typeof routeTree>, ''>>

type LastSegmentOfPath<T> = T extends '/'
  ? T
  : T extends `${infer LastSegment}/`
    ? LastSegment
    : T extends `${string}/$${infer LastSegment}`
      ? LastSegment
      : T extends `/${infer LastSegment}/${infer Action}`
        ? `${LastSegment}${Capitalize<Action>}`
        : T

type PathWithoutSlash<T> = T extends '/'
  ? 'index'
  : T extends `/${infer P}${'/' | ''}`
    ? P
    : T

type WithoutLastSlash<T> = T extends `/${infer U}/` ? `/${U}` : T

export type Routes = {
  [K in Pathname as KebabToCamel<
    PathWithoutSlash<LastSegmentOfPath<K>>
  >]: WithoutLastSlash<K>
}

export const ROUTES: Routes = {
  index: '/',
  login: '/login',
  rentAreas: '/rent-areas',
  orders: '/orders',
  orderId: '/orders/$orderId',
}

export type Route = Routes[keyof Routes]
