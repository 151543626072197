import { router } from '../../main'
import { ROUTES } from '../constants/routes'

import { getCookie, removeCookie, setCookie } from './cookies'

const COOKIES_AUTH_TOKEN = 'token-investor'
const COOKIES_EXPIRES = 30

export const getAuthToken = (): string | undefined => {
  return getCookie(COOKIES_AUTH_TOKEN)
}

export const setAuthToken = (token: string) => {
  setCookie(COOKIES_AUTH_TOKEN, token, {
    expires: COOKIES_EXPIRES,
    sameSite: 'lax',
  })
}

export const removeAuthToken = () => {
  removeCookie(COOKIES_AUTH_TOKEN)
}

export const logout = (onSuccess?: () => void) => {
  removeCookie(COOKIES_AUTH_TOKEN)
  void router
    .navigate({
      to: ROUTES.login,
    })
    .then(() => onSuccess?.())
}
