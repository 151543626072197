import { withStyles } from '@bruitt/classnames'

import { Box } from '../../../../shared/uikit/box'
import { IconButton } from '../../../../shared/uikit/icon-button'
import { IconSimpleButton } from '../../../../shared/uikit/icon-simple-button'
import { Notification, NotificationProps } from '../notification/notification'

import s from './notification-popup.module.scss'

const sx = withStyles(s)

interface NotificationPopupProps extends NotificationProps {
  onClose: () => void
  onClickRead?: () => void
}

export const NotificationPopup = ({
  onClose,
  onClickRead,
  ...notificationProps
}: NotificationPopupProps) => {
  return (
    <Box
      className={sx(s.root, { isNew: notificationProps.isNew })}
      p="16"
      rad="12"
      bgColor="base-0"
    >
      <Notification {...notificationProps} />
      <IconButton
        className={s.closeButton}
        variant="secondary"
        s="s"
        icon="close"
        onClick={onClose}
      />
      {notificationProps.isNew && (
        <IconSimpleButton
          onClick={onClickRead}
          className={s.readButton}
          icon="check"
          s="24"
        />
      )}
    </Box>
  )
}
