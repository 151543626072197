import { withStyles } from '@bruitt/classnames'

import { Flex } from '../../flex'
import { Text } from '../../text'
import { SelectProps } from '../select'
import { DefaultOption } from '../types'

import s from './option.module.scss'

const sx = withStyles(s)

export interface OptionListItem<T> {
  option: T
  isSelected: boolean
  onClickOption: (option: T) => void
  labelName: keyof T
  renderOption?: SelectProps<T>['renderOption']
}

export const Option = <T extends DefaultOption>(props: OptionListItem<T>) => {
  const { option, onClickOption, labelName, isSelected, renderOption } = props

  if (renderOption) {
    return renderOption({ option, isSelected, onClickOption })
  }

  return (
    <Flex
      onClick={() => onClickOption(option)}
      className={sx(s.option, {
        isSelected,
        // disabled: option.disabled,
      })}
      dir="column"
      g="2"
      px="16"
      py="12"
      // data-option-focus="on"
      // data-option-index={index}
      rad="6"
    >
      <Text s="body" color={isSelected ? 'primary-500' : 'text-900'}>
        {['string', 'number'].includes(typeof option[labelName])
          ? (option[labelName] as string)
          : '-'}
      </Text>
    </Flex>
  )
}
