import { useMutation } from '@tanstack/react-query'

import { getErrorTextByError } from '../../../shared/lib/get-error-text-by-error'
import { sendOtp } from '../api'

interface useSendOtpMutationParams {
  onSuccess?: () => void
}

export const useSendOtpMutation = ({
  onSuccess,
}: useSendOtpMutationParams = {}) => {
  const { mutate, isPending, error } = useMutation({
    mutationFn: sendOtp,
    onSuccess,
  })

  return {
    isPending: isPending,
    sendOtp: mutate,
    errorText: getErrorTextByError(error),
  }
}
