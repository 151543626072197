import s from './option-list-wrapper.module.scss'

interface OptionListWrapperProps {
  children?: React.ReactNode
  footer?: React.ReactNode
}

export const OptionListWrapper = ({
  children,
  footer,
}: OptionListWrapperProps) => {
  return (
    <div className={s.wrapper}>
      <div
        className={s.root}
        onMouseDown={(e) => e.preventDefault()}
        onMouseUp={(e) => e.preventDefault()}
      >
        {children}
        {footer}
      </div>
    </div>
  )
}
