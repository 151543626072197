import axios, { AxiosError } from 'axios'

import { BffError } from '../api/bff-error'

export const isAxiosError = <ResponseType>(
  error: unknown,
): error is AxiosError<ResponseType> => {
  return axios.isAxiosError(error)
}

export const isAxiosBffError = (
  error: unknown,
): error is AxiosError<BffError> =>
  isAxiosError<BffError>(error) &&
  error.response?.data?.code !== undefined &&
  error.response.data.name !== undefined &&
  error.response.data.title !== undefined
