import { useQuery } from '@tanstack/react-query'

import { getUnreadNotificationCount } from './api'
import { NOTIFICATION_COUNT_QUERY_KEY } from './constants'

export const useUnreadNotificationCount = () => {
  const { data, ...rest } = useQuery({
    queryKey: [NOTIFICATION_COUNT_QUERY_KEY],
    queryFn: getUnreadNotificationCount,
  })

  return { count: data?.count, ...rest }
}
