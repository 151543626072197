import { withStyles } from '@bruitt/classnames'
import React, { ReactNode } from 'react'

import { Box } from '../box'
import { Flex } from '../flex'

import s from './table.module.scss'

const sx = withStyles(s)

interface TableProps {
  children?: ReactNode
  className?: string
  variant?: 'white' | 'gray'
}

const Table = ({ children, className, variant = 'white' }: TableProps) => {
  return <Box className={sx(s.table, { variant }, className)}>{children}</Box>
}

interface HeadProps {
  children?: ReactNode
  cellClassName?: string
  className?: string
}

const Head = ({ children, cellClassName, className }: HeadProps) => {
  return (
    <div className={sx(s.head, className)}>
      <Flex width="full">
        {React.Children.map(
          children,
          (child) =>
            React.isValidElement(child) &&
            React.cloneElement(child as React.ReactElement, {
              className: sx(s.headCell, cellClassName),
            }),
        )}
      </Flex>
    </div>
  )
}

interface RowProps {
  children?: ReactNode
  className?: string
  cellClassName?: string
}

const Row = ({ children, className, cellClassName }: RowProps) => {
  return (
    <div className={sx(s.row, className)}>
      <Flex>
        {React.Children.map(
          children,
          (child) =>
            React.isValidElement(child) &&
            React.cloneElement(child as React.ReactElement, {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              className: sx(s.rowCell, cellClassName, child?.props?.className),
            }),
        )}
      </Flex>
    </div>
  )
}

interface CellProps {
  children?: ReactNode
  className?: string
}

const Cell = ({ children, className }: CellProps) => {
  return <div className={className}>{children}</div>
}

const Divider = ({ className }: { className?: string }) => {
  return <div className={sx(s.divider, className)} />
}

const TableNamespace = Object.assign(Table, { Head, Divider, Cell, Row })

export { TableNamespace as Table }
