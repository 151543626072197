import {
  getTimeDifference,
  isPastDate,
  TimeDifference,
} from '../../../../shared/lib/dates'
import {
  pluralizeDays,
  pluralizeHours,
  pluralizeMinutes,
} from '../../../../shared/lib/plural-ru'
import { Color } from '../../../../shared/types/colors'
import { Flex } from '../../../../shared/uikit/flex'
import { Icon, IconName } from '../../../../shared/uikit/icon'
import { Order } from '../../types'

interface OrderSlaTagProps {
  deadlineNotificationDate?: Order['deadlineNotificationDate']
  plannedCompletionDate?: Order['plannedCompletionDate']
  statusChangePlannedDate?: Order['statusChangePlannedDate']
  statusDeadlineNotificationDate?: Order['statusDeadlineNotificationDate']
  currentDate?: string
}

export const OrderSlaTag = (props: OrderSlaTagProps) => {
  const { color, bgColor, text, icon, iconColor } = getSlaProps(props)

  if (!text) return null

  return (
    <Flex
      px="12"
      py="6"
      g="6"
      rad="10"
      ai="flex-start"
      color={color}
      bgColor={bgColor}
    >
      <span>
        <Icon s="16" name={icon} color={iconColor} />
      </span>
      <span>{text}</span>
    </Flex>
  )
}

interface SlaProps {
  color: Color
  bgColor: Color
  text: string
  icon: IconName
  iconColor: Color
}

const getSlaProps = ({
  deadlineNotificationDate,
  plannedCompletionDate,
  statusChangePlannedDate,
  statusDeadlineNotificationDate,
  currentDate = new Date().toISOString(),
}: OrderSlaTagProps): SlaProps => {
  if (
    deadlineNotificationDate &&
    plannedCompletionDate &&
    isPastDate(deadlineNotificationDate)
  ) {
    const diff = getTimeDifference(plannedCompletionDate, currentDate)

    if (diff.value < 0) {
      return {
        color: 'text-500',
        iconColor: 'text-500',
        bgColor: 'red-100',
        text: `Срок выполнения нарушен на ${Math.abs(diff.value)} ${getPluralWord(diff)}`,
        icon: 'notification-3',
      }
    } else {
      return {
        color: 'text-500',
        iconColor: 'yellow-500',
        bgColor: 'yellow-50',
        text: `${Math.abs(diff.value)} ${getPluralWord(diff)} до срока выполнения`,
        icon: 'notification-3',
      }
    }
  }

  if (
    statusDeadlineNotificationDate &&
    statusChangePlannedDate &&
    isPastDate(statusDeadlineNotificationDate)
  ) {
    const diff = getTimeDifference(statusChangePlannedDate, currentDate)

    if (diff.value < 0) {
      return {
        color: 'text-500',
        iconColor: 'text-500',
        bgColor: 'blue-50',
        text: `В статусе больше запланированного времени на ${Math.abs(diff.value)} ${getPluralWord(diff)}`,
        icon: 'time',
      }
    } else {
      return {
        color: 'text-500',
        iconColor: 'yellow-500',
        bgColor: 'base-200',
        text: `${Math.abs(diff.value)} ${getPluralWord(diff)} до плановой смены статуса`,
        icon: 'loader-2',
      }
    }
  }

  // if no deadline return empty text to show nothing
  return {
    color: 'text-500',
    iconColor: 'yellow-500',
    bgColor: 'yellow-50',
    text: '',
    icon: 'notification-3',
  }
}

const getPluralWord = (diff: TimeDifference): string => {
  if (diff.days) return pluralizeDays(diff.value)
  if (diff.hours) return pluralizeHours(diff.value)
  if (diff.minutes) return pluralizeMinutes(diff.value)

  return ''
}
