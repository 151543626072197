import { withStyles } from '@bruitt/classnames'

import { Box } from '../../../../shared/uikit/box'
import { Flex } from '../../../../shared/uikit/flex'
import { Icon } from '../../../../shared/uikit/icon'
import { Text } from '../../../../shared/uikit/text'
import { NotificationIcon } from '../../types'

import s from './notification.module.scss'

const sx = withStyles(s)

export interface NotificationProps {
  icon?: NotificationIcon
  title: string
  text: string
  date?: string
  isNew?: boolean
}

export const Notification = ({
  icon,
  title,
  text,
  date,
  isNew = false,
}: NotificationProps) => {
  const IconComponent = ICON_BY_NAME[icon || 'Information']

  return (
    <Flex g="6" ai="flex-start">
      <Box className={sx(s.icon, { isNew })} p="6" rad="8" bgColor="base-50">
        {IconComponent}
      </Box>
      <Box>
        <Text s="callout-bold">{title}</Text>
        <Text s="sub-headline" mt="8" color="text-500">
          {text}
        </Text>
        {date && (
          <Text s="caption1-bold" mt="12" color="text-300">
            {date}
          </Text>
        )}
      </Box>
    </Flex>
  )
}

const ICON_BY_NAME: { [key in NotificationIcon]: React.ReactElement } = {
  Information: <Icon name="information" s="24" color="blue-500" />,
  Conversation: (
    <Icon name="question-answer-filled" s="24" color="primary-500" />
  ),
  Warning: <Icon name="alert" s="24" color="yellow-500" />,
  Error: <Icon name="spam-2" s="24" color="red-500" />,
}
