import { Controller, useFormContext } from 'react-hook-form'

import { Box } from '../../../../shared/uikit/box'
import { Button } from '../../../../shared/uikit/button'
import { Flex } from '../../../../shared/uikit/flex'
import { Icon } from '../../../../shared/uikit/icon'
import { Input } from '../../../../shared/uikit/input'
import { Select } from '../../../../shared/uikit/select'
import { Text } from '../../../../shared/uikit/text'
import { OrdersFilterFormValues } from '../../types'

import s from './desktop-filter-form.module.scss'

const OPTIONS = [
  { label: 'Label 1', value: 'value1' },
  { label: 'Label 2', value: 'value2' },
  { label: 'Label 3', value: 'value3' },
  { label: 'Label 4', value: 'value4' },
  { label: 'Label 5', value: 'value5' },
]

interface DesktopFilterFormProps {
  children?: React.ReactNode
}

export const DesktopFilterForm = (_: DesktopFilterFormProps) => {
  // const filterCollapse = useOpenable(true)
  const { register, control, reset } = useFormContext<OrdersFilterFormValues>()

  return (
    <div>
      {/* <Collapse isOpen={filterCollapse.isOpen} minHeight={40}> */}
      <Flex w="wrap" mb="24" g="12">
        <Box className={s.status}>
          <Controller
            control={control}
            name="status"
            render={({ field }) => (
              <Select
                s="m"
                type="single"
                placeholder="Статус"
                options={OPTIONS}
                isClearable
                {...field}
              />
            )}
          />
        </Box>
        <Box className={s.project}>
          <Controller
            control={control}
            name="project"
            render={({ field }) => (
              <Select
                s="m"
                type="single"
                placeholder="Проект"
                options={OPTIONS}
                isClearable
                {...field}
              />
            )}
          />
        </Box>
        <Box className={s.rentAreas}>
          <Controller
            control={control}
            name="rentArea"
            render={({ field }) => (
              <Select
                s="m"
                type="multi"
                placeholder="Помещения"
                options={OPTIONS}
                isClearable
                {...field}
              />
            )}
          />
        </Box>
        <Box className={s.type}>
          <Controller
            control={control}
            name="orderType"
            render={({ field }) => (
              <Select
                s="m"
                type="single"
                placeholder="Тип заявки"
                options={OPTIONS}
                isClearable
                {...field}
              />
            )}
          />
        </Box>
        {/* <IconButton
          className={s.toggle}
          variant="tertiary"
          icon={filterCollapse.isOpen ? 'arrow-up-s' : 'arrow-down-s'}
          s="m"
          onClick={filterCollapse.toggle}
        /> */}
        <Flex className={s.sla} span="2" ai="center" g="6">
          <Input s="m" placeholder="Срок исполнения" {...register('slaFrom')} />
          <Text s="footnote">-</Text>
          <Input s="m" placeholder="Срок исполнения" {...register('slaTo')} />
        </Flex>
        <Flex className={s.created} span="2" ai="center" g="6">
          <Input
            s="m"
            placeholder="Дата создания"
            {...register('createdFrom')}
          />
          <Text s="footnote">-</Text>
          <Input s="m" placeholder="Дата создания" {...register('createdTo')} />
        </Flex>
        <Button
          onClick={() => reset()}
          className={s.clear}
          variant="ghost"
          s="m"
          pre={<Icon name="close" color="primary-500" />}
        >
          Сбросить фильтр
        </Button>
      </Flex>
      {/* </Collapse> */}
    </div>
  )
}
