import { SortingRequestParams } from '../../../../shared/types/api-types'
import { SORT_DIRECTIONS, SortDirection } from '../../../../shared/types/sort'
import { SortButton } from '../../../../shared/uikit/sort-button'
import { Table } from '../../../../shared/uikit/table'
import { ORDER_LIST_SORT_FIELDS } from '../../constants'
import { OrderSortColumn } from '../../types'

import s from './order-head.module.scss'

interface OrderHeadProps {
  onChangeSort: (sort: SortingRequestParams<OrderSortColumn>) => void
  sorting: OrderSortColumn
  sortingDir: SortDirection
  className?: string
}

export const OrderHead = ({
  onChangeSort,
  sorting,
  sortingDir,
  className,
}: OrderHeadProps) => {
  const handleChangeSort = (s: OrderSortColumn) => () => {
    if (s !== sorting) {
      onChangeSort({
        columnName: s,
        direction: SORT_DIRECTIONS.desc,
      })
    } else if (sortingDir === SORT_DIRECTIONS.asc) {
      onChangeSort({
        columnName: ORDER_LIST_SORT_FIELDS.createdAt,
        direction: SORT_DIRECTIONS.desc,
      })
    } else if (sortingDir === SORT_DIRECTIONS.desc) {
      onChangeSort({
        columnName: s,
        direction: SORT_DIRECTIONS.asc,
      })
    }
  }

  return (
    <Table.Head cellClassName={s.cell} className={className}>
      <Table.Cell>Статус</Table.Cell>
      <Table.Cell>Проект</Table.Cell>
      <Table.Cell>Помещение</Table.Cell>
      <Table.Cell>Тип заявки</Table.Cell>
      <Table.Cell>Срок исполнения</Table.Cell>

      <Table.Cell>
        <SortButton
          variant={
            sorting === ORDER_LIST_SORT_FIELDS.createdAt
              ? sortingDir
              : 'default'
          }
          onClick={handleChangeSort(ORDER_LIST_SORT_FIELDS.createdAt)}
        >
          Дата создания
        </SortButton>
      </Table.Cell>
    </Table.Head>
  )
}
