import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ListResponse } from '../../shared/types/api-types'

import { readAllNotifications } from './api'
import {
  NOTIFICATION_COUNT_QUERY_KEY,
  NOTIFICATION_LIST_QUERY_KEY,
} from './constants'
import { NotificationListItem } from './types'

interface QueryData {
  pageParams: string[]
  pages: ListResponse<NotificationListItem>[]
}

export const useReadAllNotification = () => {
  const qc = useQueryClient()
  const { mutate } = useMutation({
    mutationFn: readAllNotifications,
    onMutate: async () => {
      await qc.cancelQueries({ queryKey: [NOTIFICATION_LIST_QUERY_KEY] })
      const previousNotifications = qc.getQueryData<QueryData>([
        NOTIFICATION_LIST_QUERY_KEY,
      ])

      qc.setQueryData<QueryData>([NOTIFICATION_LIST_QUERY_KEY], (old) => {
        const newPages = old?.pages?.map((page) => ({
          ...page,
          list: page.list?.map((n) =>
            !n.readAt
              ? {
                  ...n,
                  readAt: new Date().toISOString(),
                }
              : n,
          ),
        }))

        return { pageParams: old?.pageParams || [], pages: newPages || [] }
      })

      return { previousNotifications }
    },
    onError: (_err, _id, context) => {
      if (context?.previousNotifications) {
        qc.setQueryData(
          [NOTIFICATION_LIST_QUERY_KEY],
          context.previousNotifications,
        )
      }
    },
    onSettled: () => {
      void qc.invalidateQueries({ queryKey: [NOTIFICATION_LIST_QUERY_KEY] })
      void qc.invalidateQueries({ queryKey: [NOTIFICATION_COUNT_QUERY_KEY] })
    },
  })

  return {
    readAllNotifications: () => mutate(),
  }
}
