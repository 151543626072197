import { withStyles } from '@bruitt/classnames'

import { Flex } from '../../../../shared/uikit/flex'
import { Icon, IconName } from '../../../../shared/uikit/icon'
import { Text } from '../../../../shared/uikit/text'

import s from './mobile-menu-item.module.scss'

const sx = withStyles(s)

interface MobileMenuItemProps {
  title: string
  icon: IconName
  isActive?: boolean
}

export const MobileMenuItem = ({
  title,
  icon,
  isActive,
}: MobileMenuItemProps) => {
  return (
    <button type="button" className={sx(s.button, { isActive })}>
      <Flex dir="column" g="4" color="base-0" ai="center">
        <Icon className={s.icon} name={icon} s="20" />
        <Text s="caption2-bold" className={s.text}>
          {title}
        </Text>
      </Flex>
    </button>
  )
}
