import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'

import { getNotifications } from '../../entities/notification/api'
import {
  NOTIFICATION_LIST_QUERY_KEY,
  NOTIFICATION_LIST_SORT_FIELDS,
} from '../../entities/notification/constants'

import { NOTIFICATIONS_COUNT_PER_PAGE } from './constants'

export const useInfiniteNotifications = () => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: [NOTIFICATION_LIST_QUERY_KEY],
    queryFn: ({ pageParam }) =>
      getNotifications({
        sorting: {
          columnName: NOTIFICATION_LIST_SORT_FIELDS.createdAt,
          direction: 'Desc',
        },
        pagination: {
          page: pageParam,
          countPerPage: NOTIFICATIONS_COUNT_PER_PAGE,
        },
      }),
    getNextPageParam: (lastPage) =>
      lastPage.pagination?.hasMore
        ? lastPage.pagination.currentPage + 1
        : undefined,

    initialPageParam: 1,
    placeholderData: keepPreviousData,
  })

  return { pages: data?.pages || [], ...rest }
}
