import * as R from 'rambda'

import { EMPTY_PERMISSIONS } from './constants'
import { ORDER_FIELD_TYPES } from './constants-schema'
import { OrderSchemasDto } from './types'
import {
  CustomFieldsFormValues,
  orderSettingsFormSchema,
  OrderSettingsFormValues,
  orderStatusesSchema,
} from './types-schema'

export const parseOrderSchema = (data?: Partial<OrderSchemasDto>) => {
  {
    if (!data) return EMPTY_SCHEMA

    try {
      const fieldsSchema = orderSettingsFormSchema.safeParse(
        JSON.parse(data.fieldsSchema || ''),
      )
      const statusesSchema = orderStatusesSchema.safeParse(
        JSON.parse(data.statusesSchema || ''),
      )

      return {
        fields: fieldsSchema.success ? fieldsSchema.data.fields : [],
        layoutDetails: fieldsSchema.success
          ? fieldsSchema.data.layoutDetails
          : [],
        layoutProcess: fieldsSchema.success
          ? fieldsSchema.data.layoutProcess
          : [],
        permissions: statusesSchema.success
          ? statusesSchema.data.statuses?.[0]?.rolePermissions[0]?.permission
          : EMPTY_PERMISSIONS,
        transitions: statusesSchema.success
          ? statusesSchema.data.statuses?.[0]?.roleTransitions[0]?.transitions
          : [],
        plannedCompletionMinutes: statusesSchema.success
          ? statusesSchema.data.statuses?.[0]?.plannedCompletionMinutes
          : undefined,
      }
    } catch (error) {
      return EMPTY_SCHEMA
    }
  }
}

const EMPTY_SCHEMA = {
  fields: [],
  layoutDetails: [],
  layoutProcess: [],
  permissions: EMPTY_PERMISSIONS,
  transitions: [],
  plannedCompletionMinutes: undefined,
}

export const getOnlyNonFilesFields = (
  fields: OrderSettingsFormValues['fields'],
  values: CustomFieldsFormValues,
): CustomFieldsFormValues => {
  const fieldsMap = R.indexBy(R.prop('id'), fields)

  return Object.fromEntries(
    Object.entries(values).filter(
      ([k]) =>
        fieldsMap[parseInt(k, 10)]?.type !== ORDER_FIELD_TYPES.fileUpload &&
        fieldsMap[parseInt(k, 10)]?.type !== ORDER_FIELD_TYPES.photoUpload,
    ),
  )
}
