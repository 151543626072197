import { formatPhone } from '../../../../shared/lib/format-phone'
import { Box } from '../../../../shared/uikit/box'
import { Button } from '../../../../shared/uikit/button'
import {
  CenterModal,
  CenterModalProps,
} from '../../../../shared/uikit/center-modal'
import { Flex } from '../../../../shared/uikit/flex'
import { IconButton } from '../../../../shared/uikit/icon-button'
import { InputOtp } from '../../../../shared/uikit/input-otp'
import { Text } from '../../../../shared/uikit/text'

import s from './order-status-confirm-modal.module.scss'

const FORM_ID_CODE = 'form-code'

interface OrderStatusConfirmModalProps extends CenterModalProps {
  counter: number
  phone: string
  code: string[]
  onChangeCode: (s: string[]) => void
  isPendingSubmitOtp: boolean
  onSubmitOtp: () => void
  isPendingSendOtp: boolean
  onSendOtp: () => void
}

export const OrderStatusConfirmModal = ({
  counter,
  isOpen,
  onClose,
  phone,
  code,
  onChangeCode,
  isPendingSubmitOtp,
  onSubmitOtp,
  isPendingSendOtp,
  onSendOtp,
}: OrderStatusConfirmModalProps) => {
  return (
    <CenterModal isOpen={isOpen} onClose={onClose} className={s.root}>
      <Box p="24" p_md="32">
        <Flex jc="space-between">
          <Text s="title3-bold">Подтверждение по SMS</Text>
          <IconButton
            variant="secondary"
            s="l"
            icon="close"
            onClick={onClose}
          />
        </Flex>
        <Box className={s.wrapper}>
          <Text
            s="body"
            mt="8"
            mt_md="16"
            align="left"
            align_md="center"
            color="text-500"
          >
            Введите код, отправленный на номер <br />{' '}
            <b>{formatPhone(phone)}</b>
          </Text>
          <Box mt="16">
            <form
              className={s.form}
              id={FORM_ID_CODE}
              onSubmit={(e) => {
                e.preventDefault()
                onSubmitOtp()
              }}
            >
              <InputOtp value={code} onChange={onChangeCode} />
            </form>
          </Box>

          <Flex g="16" dir="column" mt="24" mt_md="32">
            {counter === 0 ? (
              <Button
                variant="tertiary"
                width="full"
                isLoading={isPendingSendOtp}
                onClick={onSendOtp}
                disabled={counter > 0}
              >
                Отправить код повторно
              </Button>
            ) : (
              <Text
                className={s.retryText}
                s="callout-bold"
                width="full"
                align="center"
                color="base-300"
              >
                Через 0:{counter} код можно получить повторно
              </Text>
            )}
            <Button
              form={FORM_ID_CODE}
              type="submit"
              variant="primary"
              width="full"
              isLoading={isPendingSubmitOtp}
              disabled={code.filter(Boolean).length !== 4}
            >
              Продолжить
            </Button>
          </Flex>
        </Box>
      </Box>
    </CenterModal>
  )
}
