import { QueryClient } from '@tanstack/react-query'
import { ParsedLocation, redirect } from '@tanstack/react-router'
import { isAxiosError } from 'axios'

import { getCurrentUser } from '../../entities/user/api'
import { USER_ME_QUERY_KEY } from '../../entities/user/constants'
import { Permission, User } from '../../entities/user/types'
import { ROUTES } from '../constants/routes'

import { removeAuthToken } from './auth'

interface BeforeLoadParams {
  location: ParsedLocation
  context: {
    queryClient: QueryClient
  }
}

export const beforeLoadAuthenticated = async ({
  location,
  context: { queryClient },
}: BeforeLoadParams) => {
  try {
    if (!queryClient.getQueryData([USER_ME_QUERY_KEY])) {
      const user = await getCurrentUser()
      queryClient.setQueryData([USER_ME_QUERY_KEY], user)
    }
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      removeAuthToken()

      throw redirect({
        to: ROUTES.login,
        search: {
          redirect: location.href !== '/' ? location.href : undefined,
        },
      })
    } else {
      throw error
    }
  }
}

export const beforeLoadCheckPermission =
  (permission: Permission) =>
  ({ location: _, context: { queryClient } }: BeforeLoadParams) => {
    const user = queryClient.getQueryData<User>([USER_ME_QUERY_KEY])

    if (!user?.permissions.includes(permission)) {
      throw redirect({ to: ROUTES.index })
    }
  }

export const beforeLoadCheckRole =
  (role: string) =>
  ({ location: _, context: { queryClient } }: BeforeLoadParams) => {
    const user = queryClient.getQueryData<User>([USER_ME_QUERY_KEY])

    if (user?.role?.name !== role) {
      throw redirect({ to: ROUTES.index })
    }
  }
