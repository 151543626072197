import { withStyles } from '@bruitt/classnames'
import { ReactNode } from 'react'

import { BackgroundColorProps, ColorProps } from '../../types/colors'
import { Flex } from '../flex'

import styles from './tag.module.scss'

const sx = withStyles(styles)

type Size = 's' | 'm'

export interface TagProps extends ColorProps, BackgroundColorProps {
  s?: Size
  className?: string
  pre?: ReactNode
  post?: ReactNode
  children?: ReactNode
}

export const Tag = ({
  children,
  className,
  s = 'm',
  color = 'text-900',
  pre,
  post,
  ...rest
}: TagProps) => {
  return (
    <div
      className={sx(
        styles.tag,
        { hasIcon: Boolean(pre || post), s, color },
        rest,
        className,
      )}
    >
      <Flex g="4" ai="center">
        {pre}
        {children}
        {post}
      </Flex>
    </div>
  )
}
