import { NBSP } from '../../../../shared/constants/nbsp'
import { parseDate } from '../../../../shared/lib/dates'
import { Table } from '../../../../shared/uikit/table'
import { Tag } from '../../../../shared/uikit/tag'
import { Text } from '../../../../shared/uikit/text'
import { OrderListItem } from '../../types'
import s from './/order-row.module.scss'

interface OrderRowProps {
  order: OrderListItem
}

const OrderRow = ({ order: o }: OrderRowProps) => {
  return (
    <Table.Row cellClassName={s.cell} className={s.row}>
      <div>
        <Tag s="s" bgColor="base-300">
          {o.status.title}
        </Tag>
      </div>
      <div>
        {o.projectName}
        <br />
        {o.address?.city}
      </div>
      <div>{o.rentAreaName}</div>
      <div>
        {o.typeName}. {o.subtypeName}
      </div>
      <div>{parseDate(o.plannedCompletionDate, 'dd.MM.yyyy в HH:mm')}</div>
      <div>{parseDate(o.createdDate, 'dd.MM.yyyy в HH:mm')}</div>
    </Table.Row>
  )
}

const Skeleton = () => {
  return (
    <Table.Row cellClassName={s.cell} className={s.skeletonWrapper}>
      <div>
        <Text s="footnote" className={s.skeleton} rad="8">
          {NBSP}
        </Text>
      </div>
      <div>
        <Text s="footnote" className={s.skeleton} rad="8">
          {NBSP}
        </Text>
      </div>
      <div>
        <Text s="footnote" className={s.skeleton} rad="8">
          {NBSP}
        </Text>
      </div>
      <div>
        <Text s="footnote" className={s.skeleton} rad="8">
          {NBSP}
        </Text>
      </div>
      <div>
        <Text s="footnote" className={s.skeleton} rad="8">
          {NBSP}
        </Text>
      </div>
      <div>
        <Text s="footnote" className={s.skeleton} rad="8">
          {NBSP}
        </Text>
      </div>
    </Table.Row>
  )
}

const OrderRowNamespace = Object.assign(OrderRow, { Skeleton })

export { OrderRowNamespace as OrderRow }
