import { isValidDate, parseDate } from '../../../shared/lib/dates'
import { ORDER_FIELD_TYPES } from '../constants-schema'
import { OrderSettingsFieldType } from '../types-schema'

type FieldUtils = {
  [key in OrderSettingsFieldType]: {
    formatToDto: ((s: string) => string) | ((s: string[]) => string[])
    formatFromDto: ((s: string) => string) | ((s: string[]) => string[])
    isValid?: (s: string | string[]) => string | true
  }
}

export const CUSTOM_FIELDS_UTILS: FieldUtils = {
  [ORDER_FIELD_TYPES.text]: {
    formatFromDto: (s: string) => s || '',
    formatToDto: (s: string) => s || '',
  },
  [ORDER_FIELD_TYPES.multilineText]: {
    formatFromDto: (s: string) => s || '',
    formatToDto: (s: string) => s || '',
  },
  [ORDER_FIELD_TYPES.date]: {
    formatFromDto: (s: string) => s || '',
    formatToDto: (s: string) => s || '',
  },
  [ORDER_FIELD_TYPES.dateTime]: {
    formatToDto: (s: string) => {
      const [d, m, y] = s ? s.split('.') : ''
      return d && m && y ? new Date([m, d, y].join('.')).toISOString() : ''
    },
    formatFromDto: (s: string) => parseDate(s, 'dd.MM.yyyy'),
    isValid: (s: string | string[]) => {
      if (!s) return true
      if (Array.isArray(s)) return 'Некорректная дата'

      if (s.length !== 16) return 'Некорректная дата'
      const [date = '', time = ''] = s.split(' ')
      const [d, m, y] = date.split('.')
      const [h, min] = time.split(':')

      const newDate = new Date(`${y}-${m}-${d}T${h}:${min}:00.000Z`)

      if (isValidDate(newDate) === false) return 'Некорректная дата'

      return true
    },
  },
  [ORDER_FIELD_TYPES.time]: {
    formatToDto: (s: string) => {
      const [hours, mins] = s.split(':')

      const date = new Date()
      date.setHours(parseInt(hours!, 10))
      date.setMinutes(parseInt(mins!, 10))

      return date.toISOString()
    },
    formatFromDto: (s: string) => parseDate(s, 'hh:mm'),
    isValid: (s: string | string[]) => {
      if (!s) return true
      if (Array.isArray(s)) return 'Некорректное время'

      const [hours, mins] = s.split(':')

      if (
        !hours ||
        !mins ||
        parseInt(hours, 10) < 0 ||
        parseInt(hours, 10) > 23 ||
        parseInt(mins, 10) < 0 ||
        parseInt(mins, 10) > 59
      )
        return 'Некорректное время'

      return true
    },
  },
  [ORDER_FIELD_TYPES.phone]: {
    formatToDto: (s: string) => s.replace(/\D/g, '') || '',
    formatFromDto: (s: string) => s || '',
  },
  [ORDER_FIELD_TYPES.singleSelect]: {
    formatToDto: (s: string) => s,
    formatFromDto: (s: string) => s,
  },
  [ORDER_FIELD_TYPES.multiSelect]: {
    formatToDto: (s: string) => s,
    formatFromDto: (s: string) => s,
  },
  [ORDER_FIELD_TYPES.fileUpload]: {
    formatToDto: (s: string) => s,
    formatFromDto: (s: string) => s,
  },
  [ORDER_FIELD_TYPES.photoUpload]: {
    formatToDto: (s: string) => s,
    formatFromDto: (s: string) => s,
  },
}
