import { createFileRoute, Outlet } from '@tanstack/react-router'

import { beforeLoadAuthenticated } from '../../shared/lib/before-load'
import { Layout } from '../../widgets/layout/components/layout'

export const Route = createFileRoute('/_layout-main')({
  component: () => (
    <Layout>
      <Outlet />
    </Layout>
  ),
  beforeLoad: beforeLoadAuthenticated,
})
