import { useMutation, useQueryClient } from '@tanstack/react-query'

import { confirmChangeOrderStatus } from '../api'
import { ORDER_LIST_QUERY_KEY, ORDER_QUERY_KEY } from '../constants'

interface UseChangeOrderStatusParams {
  orderId: string
  status: string
  onSuccess?: () => void
}

export const useConfirmChangeOrderStatusMutation = ({
  orderId,
  status,
  onSuccess,
}: UseChangeOrderStatusParams) => {
  const qc = useQueryClient()
  const { isPending, mutate } = useMutation({
    mutationFn: (otpCode: string) =>
      confirmChangeOrderStatus({ orderId, status, otpCode }),
    onSuccess: () => {
      void qc.refetchQueries({
        queryKey: [ORDER_LIST_QUERY_KEY],
      })
      void qc.invalidateQueries({
        queryKey: [ORDER_QUERY_KEY, orderId],
      })
      onSuccess?.()
    },
  })

  return {
    isPending,
    confirmChangeOrderStatus: mutate,
  }
}
