import { ROUTES } from '../../shared/constants/routes'

import { MenuItem } from './types'

export const useMenuItems = (): MenuItem[] => {
  return [
    {
      title: 'Главная',
      icon: 'home-6',
      to: ROUTES.index,
    },
    {
      title: 'Помещения',
      icon: 'rounded-corner',
      to: ROUTES.rentAreas,
    },
    {
      title: 'Заявки',
      icon: 'message-2',
      to: ROUTES.orders,
    },
  ]
}
