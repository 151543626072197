import axios, { AxiosRequestConfig } from 'axios'

import { config } from '../config'

import { requestHook, responseErrorHook } from './interceptors'

const axiosBff = axios.create({
  baseURL: `${config.apiUrl}/api`,
  withCredentials: true,
})

axiosBff.interceptors.request.use(requestHook)
axiosBff.interceptors.response.use((res) => res, responseErrorHook)

export const bffRequest = {
  get: <T>(url: string, config?: AxiosRequestConfig) =>
    axiosBff.get<T>(url, config).then(({ data }) => data),
  post: <T>(url: string, body?: unknown, config?: AxiosRequestConfig) =>
    axiosBff.post<T>(url, body, config).then(({ data }) => data),
  put: <T>(url: string, body?: unknown, config?: AxiosRequestConfig) =>
    axiosBff.put<T>(url, body, config).then(({ data }) => data),
  delete: <T>(url: string, config?: AxiosRequestConfig) =>
    axiosBff.delete<T>(url, config).then(({ data }) => data),
}
