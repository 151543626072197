import { useBoolean } from './use-boolean'

export const useModal = (isOpenedByDefault?: boolean) => {
  const bool = useBoolean(Boolean(isOpenedByDefault))

  return {
    isOpen: bool.isTrue,
    open: bool.setTrue,
    close: bool.setFalse,
    toggle: bool.toggle,
    setOpen: bool.setValue,
  }
}
