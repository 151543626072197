import Cookies from 'js-cookie'

const getRootDomain = (host: string) => host.split('.').slice(-2).join('.')

const domain = getRootDomain(location.hostname)

export const setCookie = (name: string, value: string, options = {}) => {
  Cookies.set(name, value, { domain, ...options })
}

export const getCookie = Cookies.get.bind(Cookies)

export const removeCookie = (name: string, options = {}) =>
  Cookies.remove(name, { domain, ...options })
