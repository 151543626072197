import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  isPast,
  isSameDay,
  isValid,
} from 'date-fns'
import { ru } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns/setDefaultOptions'

setDefaultOptions({ locale: ru })

export function parseDate(
  date: string | Date | null | undefined,
  formatString: string,
) {
  if (!date) return ''
  return format(new Date(date), formatString)
}

export const getDifferenceInDays = (
  date1: Date | string | undefined,
  date2: Date | string | undefined,
) => {
  if (!date1 || !date2) return

  return differenceInDays(date1, date2)
}

export const isValidDate = (date: Date | string) => isValid(new Date(date))

export const isPastDate = (date: Date | string) => {
  return isPast(new Date(date))
}

export const getTimeDifference = (
  from: Date | string,
  date: Date | string,
): TimeDifference => {
  const minutes = differenceInMinutes(new Date(from), new Date(date))
  const hours = differenceInHours(new Date(from), new Date(date))
  const days = differenceInDays(new Date(from), new Date(date))

  if (Math.abs(minutes) < 60) {
    return { value: minutes, minutes: true }
  } else if (Math.abs(hours) < 24) {
    return { value: hours, hours: true }
  } else {
    return { value: days, days: true }
  }
}

export type TimeDifference = {
  value: number
  days?: boolean
  hours?: boolean
  minutes?: boolean
}

export const isEqualDay = (date1?: Date | string, date2?: Date | string) => {
  if (!date1 || !date2) return false
  return isSameDay(new Date(date1), new Date(date2))
}
