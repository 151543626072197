import { useQuery } from '@tanstack/react-query'

import { getOrderFile, GetOrderFileParams } from '../api'
import { ORDER_PHOTO_QUERY_KEY } from '../constants'

export const useOrderFile = ({
  orderId,
  fieldId,
  fileName,
}: Partial<GetOrderFileParams>) => {
  const { data, ...rest } = useQuery({
    queryKey: [ORDER_PHOTO_QUERY_KEY, orderId, fieldId, fileName],
    queryFn: () =>
      getOrderFile({
        orderId: orderId!,
        fieldId: fieldId!,
        fileName: fileName!,
      }),
    enabled: Boolean(orderId && fieldId && fileName),
    retry: false,
  })

  return { photoUrl: data, ...rest }
}
