import { getRouteApi } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'

import {
  ORDER_LIST_SORT_FIELDS,
  ORDERS_FILTER_FORM_DEFAULT_VALUES,
} from '../../entities/order/constants'
import {
  OrdersFilterFormValues,
  OrderSortColumn,
} from '../../entities/order/types'
import { useModal } from '../../shared/hooks/use-modal'
import { SortingRequestParams } from '../../shared/types/api-types'
import { SORT_DIRECTIONS } from '../../shared/types/sort'

import { OrdersListSearch } from './types'

const { useSearch, useNavigate } = getRouteApi('/_layout-main/orders/')

export const useOrdersPage = () => {
  // const { permissions } = useUser()
  const mobileFilterModal = useModal()
  const {
    sorting = ORDER_LIST_SORT_FIELDS.createdAt,
    sortingDir = SORT_DIRECTIONS.desc,
  } = useSearch()
  const navigate = useNavigate()
  const handleChangeParams = (search: OrdersListSearch) => {
    void navigate({
      search,
      replace: true,
    })
  }
  const changeSort = (s: SortingRequestParams<OrderSortColumn>) =>
    handleChangeParams({ sorting: s.columnName, sortingDir: s.direction })

  const methods = useForm<OrdersFilterFormValues>({
    defaultValues: ORDERS_FILTER_FORM_DEFAULT_VALUES,
  })

  return {
    data: { sorting, sortingDir, methods, mobileFilterModal },
    actions: { changeSort },
  }
}
