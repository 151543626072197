import { useQuery, useQueryClient } from '@tanstack/react-query'
import { notify, NotifyAlignment } from 'react-simple-notify'

import { useInfiniteNotifications } from '../../features/notification-list/use-infinite-notifications'
import { useResponsive } from '../../shared/hooks/use-responsive'

import { getNotifications } from './api'
import { NotificationPopup } from './components/notification-popup'
import {
  NOTIFICATION_COUNT_QUERY_KEY,
  NOTIFICATION_LIST_QUERY_KEY,
  NOTIFICATION_LIST_SORT_FIELDS,
  NOTIFICATION_POPUP_LIST_QUERY_KEY,
} from './constants'
import { NotificationListItem } from './types'
import { useReadNotification } from './use-read-notification'

export const usePopupNotifications = () => {
  const qc = useQueryClient()
  const { pages } = useInfiniteNotifications()
  const { readNotification } = useReadNotification()
  const isMobile = useResponsive('md')

  const lastDate = pages?.[0]?.list?.[0]?.createdAt

  const showNotification = (n: NotificationListItem) => {
    notify.open({
      duration: 4_000,
      alignment: isMobile
        ? NotifyAlignment.bottomRight
        : NotifyAlignment.topRight,
      // @ts-ignore
      render: ({ onClose }) => (
        <NotificationPopup
          icon={n.icon}
          title={n.caption}
          text={n.body}
          isNew
          onClickRead={() => {
            readNotification(n.id)
            onClose()
          }}
          onClose={onClose}
        />
      ),
    })
  }

  useQuery({
    queryKey: [NOTIFICATION_POPUP_LIST_QUERY_KEY],
    queryFn: () =>
      getNotifications({
        createdAfterDate: lastDate,
        sorting: {
          columnName: NOTIFICATION_LIST_SORT_FIELDS.createdAt,
          direction: 'Desc',
        },
        pagination: {
          page: 1,
          countPerPage: 100,
        },
      }).then((data) => {
        data?.list?.forEach((n) => showNotification(n))

        if (data?.list?.length) {
          void qc.invalidateQueries({ queryKey: [NOTIFICATION_LIST_QUERY_KEY] })
          void qc.invalidateQueries({
            queryKey: [NOTIFICATION_COUNT_QUERY_KEY],
          })
        }

        return data
      }),
    enabled: Boolean(lastDate),
    refetchInterval: 60_000,
  })
}
