import { IMAGE_FILE_EXTENSIONS, IMAGE_MIME_TYPES } from '../constants/files'

// https://stackoverflow.com/a/12900504
export const getFileExtension = (name: string) =>
  name.slice((Math.max(0, name.lastIndexOf('.')) || Infinity) + 1)

export const getIsImageMimeType = (mimeType: string) =>
  IMAGE_MIME_TYPES.has(mimeType)

export const getIsImageFileExtension = (ext: string) =>
  IMAGE_FILE_EXTENSIONS.includes(`.${ext}`)
