import { useEffect, useState } from 'react'

import { CustomFieldsForm } from '../../entities/order/components/custom-fields-form'
import { useCustomFieldsForm } from '../../entities/order/components/custom-fields-form/use-custom-fields-form'
import { OrderSlaTag } from '../../entities/order/components/order-sla-tag'
import { OrderStatusConfirmModal } from '../../entities/order/components/order-status-confirm-modal'
import { OrderStatusTag } from '../../entities/order/components/order-status-tag'
import {
  ORDER_ORIGINS,
  ORDER_SMS_RETRY_TIMEOUT_SEC,
} from '../../entities/order/constants'
import { useChangeOrderStatus } from '../../entities/order/hooks/use-change-order-status'
import { OrderStatus } from '../../entities/order/types'
import { useProfileQuery } from '../../entities/user/hooks/use-profile-query'
import { PageHeader } from '../../shared/components/page-header'
import { NBSP } from '../../shared/constants/nbsp'
import { parseDate } from '../../shared/lib/dates'
import { Box } from '../../shared/uikit/box'
import { Card } from '../../shared/uikit/card'
import { FeatureValue } from '../../shared/uikit/feature-value'
import { Flex } from '../../shared/uikit/flex'
import { Grid } from '../../shared/uikit/grid'
import { Select } from '../../shared/uikit/select'
import { Text } from '../../shared/uikit/text'
import { ProfileBar } from '../../widgets/profile-bar/components/profile-bar'

import { useOrderPage } from './use-order-page'

import s from './order-page.module.scss'

export const OrderPage = () => {
  const {
    data: { orderId, order: o, formSchema },
  } = useOrderPage()

  const { values, files } = useCustomFieldsForm({
    initialValues: o?.fields,
    fields: formSchema.fields,
    permissions: formSchema.permissions,
  })

  return (
    <>
      <Box px="20" px_md="0" pt="20" pt_md="0">
        <Flex jc="space-between" mb="20" mb_md="32">
          <PageHeader>
            {o ? (
              <>
                Заявка №{o?.number.toString().padStart(7, '0')},{' '}
                {parseDate(o?.createdDate, 'dd.MM.yyyy в HH:mm')}
              </>
            ) : (
              NBSP
            )}
          </PageHeader>
          <Box hidden display_lg="block">
            <ProfileBar />
          </Box>
        </Flex>
      </Box>

      <Grid className={s.grid}>
        <Card px="20" pt="20" px_lg="24" pt_lg="24" pb="48">
          <Text s="title3" s_lg="title2">
            Детали заявки
          </Text>
          <Box mt="24">
            <CustomFieldsForm
              orderId={orderId}
              fields={formSchema.fields}
              layout={formSchema.layoutDetails}
              permissions={formSchema.permissions}
              values={values}
              files={files}
            />
          </Box>
          <Card.Divider my="20" hidden_md />

          <Box hidden_md>
            <Management />
          </Box>
        </Card>

        <Card hidden display_md="block" p="24">
          <Management />
        </Card>
      </Grid>
    </>
  )
}

const Management = () => {
  const {
    flags: { hasSla },
    data: { orderId, order: o, status, executor, statusOptions },
  } = useOrderPage()

  const { profile } = useProfileQuery()

  const [counter, setCounter] = useState(0)

  const {
    code,
    setCode,
    newStatus,
    confirmModal,
    onModalClose,
    isPendingChangeStatus,
    isPendingConfirmStatus,
    changeOrderStatus,
    confirmChangeOrderStatus,
  } = useChangeOrderStatus(orderId)

  const handleChangeStatus = (status: OrderStatus | null) => {
    if (!status) return
    changeOrderStatus(status.code)
    setCounter(ORDER_SMS_RETRY_TIMEOUT_SEC)
  }

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000)
    }
  }, [counter])

  return (
    <>
      <Grid g="16" ji="start">
        <Text s="sub-headline-bold" color="text-500">
          Управление заявкой
        </Text>

        {o && statusOptions.length > 0 ? (
          <Select
            type="single"
            placeholder="Статус"
            valueName="code"
            labelName="title"
            value={status}
            options={statusOptions}
            onChange={(status) => handleChangeStatus(status)}
            isLoading={isPendingChangeStatus}
          />
        ) : status ? (
          <OrderStatusTag status={status} />
        ) : null}

        <FeatureValue title="Исполнитель">
          {executor?.fullName || '-'}
        </FeatureValue>

        <FeatureValue title="Плановый срок">
          <div>
            {parseDate(o?.plannedCompletionDate, 'dd.MM.yyyy в HH:mm') || NBSP}
          </div>

          {hasSla && (
            <Box mt="4">
              <OrderSlaTag
                deadlineNotificationDate={o?.deadlineNotificationDate}
                plannedCompletionDate={o?.plannedCompletionDate}
                statusChangePlannedDate={o?.statusChangePlannedDate}
                statusDeadlineNotificationDate={
                  o?.statusDeadlineNotificationDate
                }
              />
            </Box>
          )}
        </FeatureValue>

        <Card bgColor="base-50" width="full">
          <Card.Body>
            <Grid rg="16">
              <FeatureValue title="Проект">
                {o?.rentArea?.projectName || '-'}
              </FeatureValue>
              <FeatureValue title="Помещение">
                {o?.rentArea?.unit || o?.rentArea?.name || '-'}
              </FeatureValue>
            </Grid>
          </Card.Body>
        </Card>

        <FeatureValue title="Тип">{o?.type.name || '-'}</FeatureValue>

        <FeatureValue title="Тематика">{o?.subtype.name || '-'}</FeatureValue>

        <FeatureValue title="Адрес">
          {o?.rentArea?.rawAddress || '-'}
        </FeatureValue>

        <FeatureValue title="Автор">
          {o?.initiator.fullName || '-'}
        </FeatureValue>

        <FeatureValue title="Источник обращения" color="text-300">
          {o?.orderOrigin ? ORDER_ORIGINS[o.orderOrigin] : '-'}
        </FeatureValue>

        <FeatureValue title="Последние изменения" color="text-300">
          {parseDate(o?.changedDate, 'dd.MM.yyyy в HH:mm') || '-'}
        </FeatureValue>
      </Grid>

      <OrderStatusConfirmModal
        counter={counter}
        isOpen={confirmModal.isOpen}
        onClose={onModalClose}
        code={code}
        onChangeCode={setCode}
        phone={profile?.phone || ''}
        isPendingSendOtp={isPendingChangeStatus}
        isPendingSubmitOtp={isPendingConfirmStatus}
        onSendOtp={() => changeOrderStatus(newStatus)}
        onSubmitOtp={() => confirmChangeOrderStatus(code.join(''))}
      />
    </>
  )
}
