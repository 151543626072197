import { z } from 'zod'

import { zodEnum } from '../../shared/lib/zod-enum'

import { LOGIN_STEPS } from './constants'

export const loginSearchParamsSchema = z.object({
  step: z
    .enum(zodEnum(Object.values(LOGIN_STEPS)))
    .optional()
    .catch(undefined),
  redirect: z.string().optional(),
})

export type LoginSearchParams = z.infer<typeof loginSearchParamsSchema>

export type LoginStep = z.infer<typeof loginSearchParamsSchema>['step']
