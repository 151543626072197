import { withStyles } from '@bruitt/classnames'
import { forwardRef, ReactNode } from 'react'

import { Color } from '../../types/colors'
import { Loader } from '../loader'
import {
  ButtonVariant,
  StyledButton,
  StyledButtonProps,
} from '../styled-button'

import styles from './button.module.scss'

const sx = withStyles(styles)

interface ButtonProps extends StyledButtonProps {
  s?: 's' | 'm' | 'l' | 'xl'
  width?: 'auto' | 'full'
  pre?: ReactNode
  post?: ReactNode
  isLoading?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      s = 'xl',
      width = 'auto',
      className,
      children,
      pre,
      post,
      isLoading = false,
      ...rest
    },
    ref,
  ) => {
    return (
      <StyledButton
        ref={ref}
        className={sx(
          styles.button,
          { s, width, hasIcon: Boolean(pre || post), isLoading },
          className,
        )}
        {...rest}
      >
        <span className={styles.content}>
          {pre && <span className={styles.enhancer}>{pre}</span>}
          {children}
          {post && <span className={styles.enhancer}>{post}</span>}
        </span>

        <Loader.Overlay isActive={isLoading}>
          <Loader
            className={styles.loaderIcon}
            color={LOADER_COLOR_BY_VARIANT_MAP[rest.variant]}
          />
        </Loader.Overlay>
      </StyledButton>
    )
  },
)

const LOADER_COLOR_BY_VARIANT_MAP: { [key in ButtonVariant]: Color } = {
  primary: 'base-0',
  secondary: 'primary-500',
  tertiary: 'primary-500',
  ghost: 'text-300',
  warning: 'red-400',
}

Button.displayName = 'Button'
