import { bffRequest } from '../../shared/api/bff-request'
import {
  ListResponse,
  PaginationRequestParams,
  SortingRequestParams,
} from '../../shared/types/api-types'

import {
  GetNotificationsFilter,
  NotificationListItem,
  NotificationsSortColumn,
} from './types'

interface GetNotificationsRequest extends GetNotificationsFilter {
  pagination: PaginationRequestParams
  sorting?: SortingRequestParams<NotificationsSortColumn>
}

export const getNotifications = (body: GetNotificationsRequest) =>
  bffRequest.post<ListResponse<NotificationListItem>>(
    '/Notifications/Search',
    body,
  )

export const readNotification = (notificationInAppId: string) =>
  bffRequest.post(
    `/Notifications/Read?notificationInAppId=${notificationInAppId}`,
  )

export const readAllNotifications = () =>
  bffRequest.post('/Notifications/ReadAll')

export const getUnreadNotificationCount = () =>
  bffRequest.get<{ count: number }>(
    '/Notifications/GetUnreadNotificationsCount',
  )
