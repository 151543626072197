import { useQuery } from '@tanstack/react-query'

import { getProfile } from '../api'
import { USER_PROFILE_QUERY_KEY } from '../constants'

export const useProfileQuery = () => {
  const { data: profile, ...rest } = useQuery({
    queryKey: [USER_PROFILE_QUERY_KEY],
    queryFn: getProfile,
  })

  return { profile, ...rest }
}
