// Format phone number
// 79605309279 -> +7 (960) 530-92-79
export const formatPhone = (phone?: string | null): string => {
  if (!phone) return ''
  const phoneDigits = phone.replace(/\D/g, '')
  if (phoneDigits.length !== 11) return phone

  const [, a, b, c, d, e] =
    phoneDigits.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/) || []

  return `+${a} (${b}) ${c}-${d}-${e}`
}
