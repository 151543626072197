import { parseEnv } from 'znv'
import { z } from 'zod'

// const PROD_HOST = 'crm.renda.su'

const env = parseEnv(import.meta.env, {
  VITE_RENDA_API_URL_TEST: z.string(),
  VITE_RENDA_API_URL_PROD: z.string(),
})

export const config = {
  apiUrl: env.VITE_RENDA_API_URL_TEST,
  // apiUrl:
  //   window.location.host === PROD_HOST
  //     ? env.VITE_RENDA_API_URL_PROD
  //     : env.VITE_RENDA_API_URL_TEST,
}
