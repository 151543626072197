import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import { routeTree } from './routeTree.gen'

import './main.scss'

import { notify, NotifyAlignment, NotifyContainers } from 'react-simple-notify'

import { NotificationPopup } from './entities/notification/components/notification-popup'
import { MEDIA_QUERIES } from './shared/constants/media-queris'
import { ROUTES } from './shared/constants/routes'
import { isAxiosBffError } from './shared/lib/is-axios-bff-error'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
    mutations: {
      onError: (error) => {
        if ([ROUTES.login].includes(router.latestLocation.href) === false) {
          const text = isAxiosBffError(error)
            ? error.response?.data.title
            : undefined

          const title = isAxiosBffError(error)
            ? error.response?.data.caption
            : undefined

          const match = matchMedia(MEDIA_QUERIES.md)

          notify.open({
            duration: 6_000,
            alignment: match.matches
              ? NotifyAlignment.bottomRight
              : NotifyAlignment.topRight,
            // @ts-ignore
            render: ({ onClose }) => (
              <NotificationPopup
                icon="Error"
                title={title || 'Что-то пошло не так'}
                text={text || 'Пожалуйста, попробуйте еще раз'}
                onClose={onClose}
              />
            ),
          })
        }
      },
    },
  },
})

export const router = createRouter({
  routeTree,
  context: { queryClient },
  defaultPreload: 'intent',
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const rootElement = document.getElementById('app')!

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
      <NotifyContainers />
    </StrictMode>,
  )
}
