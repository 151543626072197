import { withStyles } from '@bruitt/classnames'
import { ReactNode } from 'react'

import { ColorProps } from '../../types/colors'
import { Box } from '../box'
import { Flex } from '../flex'

import s from './loader.module.scss'

const sx = withStyles(s)

interface LoaderProps extends ColorProps {
  className?: string
}

const Loader = ({ color, className }: LoaderProps) => {
  return (
    <Flex g="6" jc="center" ai="center">
      <Box className={sx(s.dot, className)} rad="half" bgColor={color} />
      <Box className={sx(s.dot, className)} rad="half" bgColor={color} />
      <Box className={sx(s.dot, className)} rad="half" bgColor={color} />
    </Flex>
  )
}

interface LoaderOverlayProps {
  isActive?: boolean
  className?: string
  children: ReactNode
}

const Overlay = ({ children, isActive, className }: LoaderOverlayProps) => {
  if (!isActive) return null

  return <div className={sx(s.overlay, className)}>{children}</div>
}

const InBox = (props: LoaderProps) => {
  return (
    <Flex className={s.box} jc="center" ai="center">
      <Loader {...props} />
    </Flex>
  )
}

const LoaderNamespace = Object.assign(Loader, { Overlay, InBox })

export { LoaderNamespace as Loader }
