import { useEffect, useState } from 'react'

import { debounce } from '../../shared/lib/debounce'

export const useDetectKeyboardOpen = () => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false)
  // const isMobile = useResponsive("s")

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFocusIn = ({ target }: any) => {
      if (
        target.tagName === 'INPUT' &&
        (target.type === 'text' || target.type === 'number') &&
        !target.readOnly
      )
        setIsKeyboardOpen(true)
    }

    const handleFocusOut = () => {
      setIsKeyboardOpen(false)
    }

    // only android
    const handleResize = debounce(() => {
      if (window.visualViewport && /Android/i.test(navigator.userAgent)) {
        setIsKeyboardOpen(
          // isMobile && window.screen.height - 300 > window.visualViewport.height,
          window.screen.height - 300 > window.visualViewport.height,
        )
      }
    }, 300)

    document.addEventListener('focusin', handleFocusIn)
    document.addEventListener('focusout', handleFocusOut)

    window.visualViewport?.addEventListener('resize', handleResize)

    return () => {
      document.removeEventListener('focusin', handleFocusIn)
      document.removeEventListener('focusout', handleFocusOut)

      window.visualViewport?.removeEventListener('resize', handleResize)
    }
  }, [isKeyboardOpen])

  return isKeyboardOpen
}
