import { withStyles } from '@bruitt/classnames'
import { keys, omit, pick } from 'rambda'

import { Values } from '../../types/helpers'
import { TextAlign } from '../../types/typography'
import { Box, BoxProps } from '../box'

import { TEXT_VARIANTS } from './constants'

import s from './text.module.scss'

const sx = withStyles(s)

// LargeTitle - 34 41 700
// Title1 - 28 34 700
// Title2 - 22 28 700
// Title3 - 20 25 600
// Body - 16 22 600
// Callout - 16 21 600
// SubHeadline - 15 20 600
// Footnote - 13 18 600
// Caption1 - 12 16 600
// Caption2 - 11 13 600

type Variant = Values<typeof TEXT_VARIANTS>

interface FontProps {
  s?: Variant
  s_sm?: Variant
  s_md?: Variant
  s_lg?: Variant
  s_xl?: Variant
  // text-align
  align?: TextAlign
  align_sm?: TextAlign
  align_md?: TextAlign
  align_lg?: TextAlign
  align_xl?: TextAlign
}

type TextPropsKey = keyof FontProps

const TEXT_PROPS: { [key in TextPropsKey]: true } = {
  s: true,
  s_sm: true,
  s_md: true,
  s_lg: true,
  s_xl: true,
  align: true,
  align_sm: true,
  align_md: true,
  align_lg: true,
  align_xl: true,
}

const TEXT_PROPS_KEYS = keys(TEXT_PROPS)

export interface TextProps extends FontProps, BoxProps {}

export const Text = ({ children, className, ...props }: TextProps) => {
  const textProps = pick(TEXT_PROPS_KEYS, props)
  const rest = omit(TEXT_PROPS_KEYS, props)

  return (
    <Box className={sx(className, textProps)} {...rest}>
      {children}
    </Box>
  )
}
