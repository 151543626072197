import { useQuery } from '@tanstack/react-query'

import { getOrder } from '../api'
import { ORDER_QUERY_KEY } from '../constants'

export const useOrderQuery = (id?: string) => {
  const { data, ...rest } = useQuery({
    queryKey: [ORDER_QUERY_KEY, id],
    queryFn: () => getOrder(id!),

    enabled: Boolean(id),
  })

  return { order: data, ...rest }
}
