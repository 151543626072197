import { withStyles } from '@bruitt/classnames'
import { ReactNode } from '@tanstack/react-router'

import { getFileExtension, getIsImageFileExtension } from '../../lib/files'
import { BaseButton } from '../base-button'
import { Flex } from '../flex'
import { Icon } from '../icon'
import { Excel, Pdf, Word } from '../svg'
import { Text } from '../text'

import s from './file-preview.module.scss'

const sx = withStyles(s)

interface FilePreviewProps {
  isError?: boolean
  url?: string
  onDelete?: () => void
  onRefetch?: () => void
  fileName?: string
}

export const FilePreview = ({
  isError,
  url,
  onDelete,
  onRefetch,
  fileName = '',
}: FilePreviewProps) => {
  const fileExt = getFileExtension(fileName)
  const isImage = getIsImageFileExtension(fileExt)
  const documentIcon = DOCUMENT_ICON_BY_EXTENSION[fileExt]

  return (
    <div className={s.wrapper}>
      <div className={sx(s.imageWrapper, { isLoading: !url })}>
        {url && onDelete && (
          <BaseButton
            className={s.closeButton}
            onClick={(e) => {
              e.preventDefault()
              onDelete()
            }}
          >
            <Icon name="close" s={14} color="base-0" />
          </BaseButton>
        )}

        {isError && onRefetch && (
          <BaseButton
            className={s.refetchButton}
            onClick={(e) => {
              e.preventDefault()
              onRefetch()
            }}
          >
            <Icon name="refresh" s="24" color="text-200" />
          </BaseButton>
        )}

        {url ? (
          isImage ? (
            <img className={s.image} src={url} />
          ) : (
            <Flex
              rad="10"
              jc="center"
              ai="center"
              width="full"
              height="full"
              bgColor="base-200"
            >
              {documentIcon}
            </Flex>
          )
        ) : null}
      </div>
      <Text s="caption2" className={s.fileName} color="text-500" px="6" mt="4">
        {fileName}
      </Text>
    </div>
  )
}

const DOCUMENT_ICON_BY_EXTENSION: {
  [key: string]: ReactNode
} = {
  jpg: null,
  jpeg: null,
  png: null,
  doc: <Word />,
  docx: <Word />,
  pdf: <Pdf />,
  xls: <Excel />,
  xlsx: <Excel />,
}
