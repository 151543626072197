import { Text, TextProps } from '../text'

interface ErrorTextProps extends TextProps {
  text?: string
}

export const ErrorText = ({ text, ...rest }: ErrorTextProps) => {
  return text ? (
    <Text s="caption1" color="red-500" mt="8" {...rest}>
      {text}
    </Text>
  ) : null
}
