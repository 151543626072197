import { ReactElement, SVGProps } from 'react'

export const Word = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="6"
      y="2"
      width="16"
      height="20"
      rx="1.5"
      fill="url(#paint0_linear_2382_374)"
    />
    <path
      opacity="0.2"
      d="M11.2889 6.71875H6V17.7614H11.2889C11.5411 17.7602 11.7826 17.6672 11.9612 17.5026C12.1397 17.3379 12.2409 17.1148 12.2428 16.8816V7.59847C12.2409 7.36531 12.1397 7.14223 11.9612 6.97756C11.7826 6.8129 11.5411 6.71991 11.2889 6.71875Z"
      fill="#3C3C43"
      fillOpacity="0.6"
    />
    <path
      d="M2.46216 6.71875H11.3535C11.589 6.71856 11.815 6.81111 11.9817 6.97606C12.1485 7.14102 12.2424 7.36489 12.2428 7.59847V16.4015C12.2424 16.6351 12.1485 16.859 11.9817 17.0239C11.815 17.1889 11.589 17.2814 11.3535 17.2812H2.46216C2.34551 17.2814 2.22996 17.2588 2.12213 17.2147C2.01429 17.1706 1.91628 17.1059 1.83369 17.0242C1.75111 16.9425 1.68556 16.8454 1.64081 16.7386C1.59606 16.6318 1.57297 16.5172 1.57288 16.4015V7.59847C1.57297 7.48278 1.59606 7.36825 1.64081 7.26142C1.68556 7.15458 1.75111 7.05755 1.83369 6.97585C1.91628 6.89415 2.01429 6.82939 2.12213 6.78528C2.22996 6.74116 2.34551 6.71856 2.46216 6.71875Z"
      fill="url(#paint1_linear_2382_374)"
    />
    <path
      d="M4.66477 14.8182L3 9H4.34375L5.30682 13.0426H5.35511L6.41761 9H7.56818L8.62784 13.0511H8.67898L9.64205 9H10.9858L9.32102 14.8182H8.12216L7.0142 11.0142H6.96875L5.86364 14.8182H4.66477Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2382_374"
        x1="14"
        y1="2"
        x2="14"
        y2="22"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#41A5EE" />
        <stop offset="1" stopColor="#103F91" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2382_374"
        x1="3.43039"
        y1="6.02739"
        x2="10.299"
        y2="18.022"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#41A5EE" />
        <stop offset="0.5" stopColor="#2364A9" />
        <stop offset="1" stopColor="#103F91" />
      </linearGradient>
    </defs>
  </svg>
)
