import { withStyles } from '@bruitt/classnames'

import { NBSP } from '../../shared/constants/nbsp'
import { formatPhone } from '../../shared/lib/format-phone'
import { Box } from '../../shared/uikit/box'
import { Button } from '../../shared/uikit/button'
import { ErrorText } from '../../shared/uikit/error-text'
import { Flex } from '../../shared/uikit/flex'
import { InputOtp } from '../../shared/uikit/input-otp'
import { InputPattern } from '../../shared/uikit/input-pattern'
import { LinkButton } from '../../shared/uikit/link-button'
import { RussianFlag } from '../../shared/uikit/svg/russian-flag'
import { Text } from '../../shared/uikit/text'

import { LOGIN_STEPS } from './constants'
import { useLoginPage } from './use-login-page'

import s from './login-page.module.scss'

const sx = withStyles(s)

const FORM_ID_PHONE = 'form-phone'
const FORM_ID_CODE = 'form-code'

export const LoginPage = () => {
  const {
    flags: { isPendingSendOtp, isPendingLogin },
    data: {
      step,
      code,
      phone,
      counter,
      codeError,
      phoneError,
      sendOtpError,
      loginError,
    },
    actions: {
      handleChangeStep,
      handleChangeCode,
      handleChangePhone,
      handleSubmitPhone,
      handleSubmitCode,
    },
  } = useLoginPage()

  return (
    <Flex
      className={s.root}
      p="20"
      p_lg="24"
      dir="column"
      dir_lg="row"
      g_lg="24"
    >
      <Box
        className={s.image}
        hidden={step === LOGIN_STEPS.code}
        display_md="block"
      >
        <img src="/257-logo.svg" className={s.logo257} alt="logo" />
        <img src="/logo-white.svg" className={s.logoRenda} alt="logo" />
        <Text
          s="caption1-bold"
          s_lg="callout-bold"
          className={s.slogan}
          color="base-0"
        >
          Управляй вместе с нами
        </Text>
      </Box>

      <Flex className={s.body} dir="column">
        {step === LOGIN_STEPS.code && (
          <Box width="full" className={s.backButton}>
            <LinkButton
              s="xl"
              pre="arrow-left"
              onClick={() => handleChangeStep(LOGIN_STEPS.phone)}
            >
              Назад
            </LinkButton>
          </Box>
        )}

        <Flex className={s.wrapper} dir="column">
          {step === LOGIN_STEPS.phone ? (
            <Box className={sx(s.content, s.contentPhone)}>
              <Text s="title1-bold" s_lg="large-title-bold">
                Давайте начнём!
              </Text>
              <Text s="body" color="text-500" mt="8">
                Укажите номер телефона, чтобы мы могли отправить вам код
                подтверждения
              </Text>
              <Flex mt="32" g="8">
                <Flex
                  jc="center"
                  ai="center"
                  g="12"
                  p="16"
                  rad="10"
                  bgColor="base-50"
                >
                  <RussianFlag />
                  <Text s="body" color="text-300">
                    +7
                  </Text>
                </Flex>
                <form
                  className={s.form}
                  id={FORM_ID_PHONE}
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmitPhone()
                  }}
                >
                  <InputPattern
                    value={phone}
                    onChange={(e) => handleChangePhone(e.target.value)}
                    placeholder="Телефон"
                    format={'### ### ####'}
                    allowEmptyFormatting={false}
                    autoComplete="tel"
                    onInput={(e) => {
                      const value = e.currentTarget.value.replace(/\D/g, '')

                      if (value.length > 10) {
                        const a = value.slice(-10).slice(0, 3)
                        const b = value.slice(-10).slice(3, 6)
                        const c = value.slice(-10).slice(6, 10)

                        e.currentTarget.value = [a, b, c]
                          .filter(Boolean)
                          .join(' ')
                      }
                    }}
                  />
                </form>
              </Flex>
              <ErrorText text={phoneError || sendOtpError || NBSP} />
            </Box>
          ) : step === LOGIN_STEPS.code ? (
            <Flex
              jc="center"
              height="full"
              ai="center"
              className={sx(s.content, s.contentCode)}
              mt_md="32"
            >
              <Box width="full">
                <Text s="title1-bold" s_lg="large-title-bold">
                  Подтверждение входа
                </Text>
                <Text s="body" color="text-500" mt="8">
                  Введите код, отправленный <br /> на номер телефона{' '}
                  <b>{formatPhone(`7${phone}`)}</b>
                </Text>
                <Box mt="32">
                  <form
                    className={s.form}
                    id={FORM_ID_CODE}
                    onSubmit={(e) => {
                      e.preventDefault()
                      handleSubmitCode()
                    }}
                  >
                    <InputOtp value={code} onChange={handleChangeCode} />
                  </form>
                </Box>
                <ErrorText text={codeError || loginError || NBSP} />
              </Box>
            </Flex>
          ) : null}
        </Flex>
        <Box className={s.buttons} mt="8" mt_lg="16" mb="24">
          {step === LOGIN_STEPS.phone && (
            <Button
              form={FORM_ID_PHONE}
              type="submit"
              variant="primary"
              width="full"
              isLoading={isPendingSendOtp}
            >
              Продолжить
            </Button>
          )}

          {step === LOGIN_STEPS.code && (
            <Flex g="16" dir="column">
              {counter === 0 ? (
                <Button
                  variant="tertiary"
                  width="full"
                  isLoading={isPendingSendOtp}
                  onClick={handleSubmitPhone}
                  disabled={counter > 0}
                >
                  Отправить код повторно
                </Button>
              ) : (
                <Text
                  className={s.retryText}
                  s="callout-bold"
                  width="full"
                  align="center"
                  color="base-300"
                >
                  Через 0:{counter} код можно получить повторно
                </Text>
              )}
              <Button
                form={FORM_ID_CODE}
                type="submit"
                variant="primary"
                width="full"
                isLoading={isPendingLogin}
                disabled={code.filter(Boolean).length !== 4}
              >
                Продолжить
              </Button>
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  )
}
