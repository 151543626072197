import { withStyles } from '@bruitt/classnames'
import { forwardRef } from 'react'

import { BaseButton, BaseButtonProps } from '../base-button'

import s from './styled-button.module.scss'

const sx = withStyles(s)

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'ghost'
  | 'warning'

export interface StyledButtonProps extends BaseButtonProps {
  variant: ButtonVariant
  disabled?: boolean
  isActive?: boolean
}

export const StyledButton = forwardRef<HTMLButtonElement, StyledButtonProps>(
  (
    { variant, disabled = false, isActive = false, className, ...rest },
    ref,
  ) => {
    return (
      <BaseButton
        ref={ref}
        className={sx(s.button, className, { variant, disabled, isActive })}
        {...rest}
        disabled={disabled}
      />
    )
  },
)

StyledButton.displayName = 'StyledButton'
