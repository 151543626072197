import { ReactElement, SVGProps } from 'react'

export const Excel = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="6" y="2" width="16" height="20" rx="1.5" fill="#16864C" />
    <path
      opacity="0.2"
      d="M11.2889 6.71875H6V17.7614H11.2889C11.5411 17.7602 11.7826 17.6672 11.9612 17.5026C12.1397 17.3379 12.2409 17.1148 12.2428 16.8816V7.59847C12.2409 7.36531 12.1397 7.14223 11.9612 6.97756C11.7826 6.8129 11.5411 6.71991 11.2889 6.71875Z"
      fill="#3C3C43"
      fillOpacity="0.6"
    />
    <path
      d="M2.46216 6.71875H11.3535C11.589 6.71856 11.815 6.81111 11.9817 6.97606C12.1485 7.14102 12.2424 7.36489 12.2428 7.59847V16.4015C12.2424 16.6351 12.1485 16.859 11.9817 17.0239C11.815 17.1889 11.589 17.2814 11.3535 17.2812H2.46216C2.34551 17.2814 2.22996 17.2588 2.12213 17.2147C2.01429 17.1706 1.91628 17.1059 1.83369 17.0242C1.75111 16.9425 1.68556 16.8454 1.64081 16.7386C1.59606 16.6318 1.57297 16.5172 1.57288 16.4015V7.59847C1.57297 7.48278 1.59606 7.36825 1.64081 7.26142C1.68556 7.15458 1.75111 7.05755 1.83369 6.97585C1.91628 6.89415 2.01429 6.82939 2.12213 6.78528C2.22996 6.74116 2.34551 6.71856 2.46216 6.71875Z"
      fill="url(#paint0_linear_2045_13)"
    />
    <path
      d="M4.32861 14.8606L6.19879 11.9918L4.48576 9.13916H5.86139L6.79611 10.9658C6.8825 11.1386 6.94507 11.2672 6.97337 11.3529H6.98603C7.0471 11.214 7.1119 11.0803 7.17968 10.9496L8.17919 9.14211H9.44533L7.68837 11.9785L9.49002 14.8629H8.14269L7.06274 12.8604C7.0126 12.7743 6.96999 12.6841 6.93538 12.5908H6.91751C6.88602 12.6817 6.84403 12.7687 6.79238 12.8501L5.68041 14.8606H4.32861Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2045_13"
        x1="3.43039"
        y1="6.02739"
        x2="10.299"
        y2="18.022"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18884F" />
        <stop offset="0.5" stopColor="#117E43" />
        <stop offset="1" stopColor="#0B6631" />
      </linearGradient>
    </defs>
  </svg>
)
