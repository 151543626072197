import { Box } from '../../uikit/box'
import { Button } from '../../uikit/button'
import { Flex } from '../../uikit/flex'
import { Icon } from '../../uikit/icon'
import { Text } from '../../uikit/text'

interface EmptyListProps {
  title: string
  desc?: string
  onClick?: () => void
  imgSrc: string
}

export const EmptyList = ({ title, desc, onClick, imgSrc }: EmptyListProps) => {
  return (
    <Flex dir="column" ai="center">
      <img src={imgSrc} width={160} height={160} alt="Not found" />
      <Text s="title3-bold" mt="16">
        {title}
      </Text>
      {onClick && desc && (
        <Text s="sub-headline" mt="8" color="text-500">
          {desc}
        </Text>
      )}
      {onClick && (
        <Box mt="32">
          <Button variant="primary" pre={<Icon name="add" />} onClick={onClick}>
            Добавить
          </Button>
        </Box>
      )}
    </Flex>
  )
}
