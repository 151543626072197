import { Text } from '../../uikit/text'

interface PageHeaderProps {
  children?: React.ReactNode
}

export const PageHeader = ({ children, ...rest }: PageHeaderProps) => {
  return (
    <Text s="title3-bold" s_md="title2-bold" s_lg="title1-bold" {...rest}>
      {children}
    </Text>
  )
}
