import { OrdersFilterFormValues } from './types'

export const ORDER_QUERY_KEY = 'order'
export const ORDER_LIST_QUERY_KEY = 'order-list'
export const ORDER_PHOTO_QUERY_KEY = 'order-photo'

export const ORDER_LIST_SORT_FIELDS = {
  createdAt: 'CreatedAt',
  // status: 'Status',
  // typeName: 'TypeName',
  // subtypeName: 'SubtypeName',
  // projectName: 'ProjectName',
  // personClientFullName: 'PersonClientFullName',
  // executorFullName: 'ExecutorFullName',
} as const

export const ORDERS_FILTER_FORM_DEFAULT_VALUES: OrdersFilterFormValues = {
  status: null,
  project: null,
  rentArea: [],
  orderType: null,
  slaFrom: '',
  slaTo: '',
  createdFrom: '',
  createdTo: '',
}

export const EMPTY_PERMISSIONS = {
  visible: [],
  canEdit: [],
  required: [],
}

export const ORDER_ORIGINS_FORM_VALUES: { [key: string]: string } = {
  IncomingCall: 'Входящий звонок',
  FaceToFaceVisit: 'Очный визит',
  SocialMedia: 'Социальные сети',
  Messengers: 'Мессенджеры',
}

export const ORDER_ORIGINS: { [key: string]: string } = {
  ...ORDER_ORIGINS_FORM_VALUES,
  RentMobileApplication: 'МП Арендатора',
  EmployeeMobileApplication: 'МП Сотрудника',
}

export const ORDER_SMS_RETRY_TIMEOUT_SEC = 60
