import { withStyles } from '@bruitt/classnames'

import { useDetectKeyboardOpen } from '../../use-detect-keyboard-open'

import s from './mobile-menu.module.scss'

const sx = withStyles(s)

interface MobileMenuProps {
  children?: React.ReactNode
}

export const MobileMenu = ({ children }: MobileMenuProps) => {
  const isKeyboardOpen = useDetectKeyboardOpen()

  return (
    <div className={sx('root', { isHidden: isKeyboardOpen })}>
      <div className={sx('fixed')}>
        <div className={sx('inner')}>
          <nav className={sx('items')}>{children}</nav>
        </div>
      </div>
    </div>
  )
}
