import { withStyles } from '@bruitt/classnames'
import { forwardRef, ReactNode } from 'react'

import { IconSize } from '../../types/icons'
import { BaseButton, BaseButtonProps } from '../base-button'
import { Icon, IconName } from '../icon'

import styles from './link-button.module.scss'

const sx = withStyles(styles)

type Size = 'm' | 'l' | 'xl'

interface LinkButtonProps extends BaseButtonProps {
  variant?: 'primary' | 'secondary'
  children?: ReactNode
  s?: Size
  pre?: IconName
  post?: IconName
}

export const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>(
  (
    {
      children,
      s = 'xl',
      variant = 'primary',
      pre,
      post,
      className,
      disabled,
      ...rest
    }: LinkButtonProps,
    ref,
  ) => {
    return (
      <BaseButton
        ref={ref}
        className={sx('button', { s, disabled, variant }, className)}
        disabled={disabled}
        {...rest}
      >
        {pre && <Icon name={pre} s={ICON_SIZE_BY_BUTTON_SIZE[s]} />}
        {children}
        {post && <Icon name={post} s={ICON_SIZE_BY_BUTTON_SIZE[s]} />}
      </BaseButton>
    )
  },
)

LinkButton.displayName = 'LinkButton'

const ICON_SIZE_BY_BUTTON_SIZE: { [key in Size]: IconSize } = {
  xl: '20',
  l: '20',
  m: '16',
}
