import { Controller, useFormContext } from 'react-hook-form'

import { Button } from '../../../../shared/uikit/button'
import { Flex } from '../../../../shared/uikit/flex'
import { Input } from '../../../../shared/uikit/input'
import { Select } from '../../../../shared/uikit/select'
import { Text } from '../../../../shared/uikit/text'
import { OrdersFilterFormValues } from '../../types'

const OPTIONS = [
  { label: 'Label 1', value: 'value1' },
  { label: 'Label 2', value: 'value2' },
  { label: 'Label 3', value: 'value3' },
  { label: 'Label 4', value: 'value4' },
  { label: 'Label 5', value: 'value5' },
]

interface MobileFilterFormProps {
  children?: React.ReactNode
}

export const MobileFilterForm = (_: MobileFilterFormProps) => {
  const { register, control, reset } = useFormContext<OrdersFilterFormValues>()

  return (
    <Flex dir="column" g="12" height="full" jc="space-between">
      <Flex dir="column" g="12">
        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <Select
              s="m"
              type="single"
              placeholder="Статус"
              options={OPTIONS}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="project"
          render={({ field }) => (
            <Select
              s="m"
              type="single"
              placeholder="Проект"
              options={OPTIONS}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="rentArea"
          render={({ field }) => (
            <Select
              s="m"
              type="multi"
              placeholder="Помещения"
              options={OPTIONS}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="orderType"
          render={({ field }) => (
            <Select
              s="m"
              type="single"
              placeholder="Тип заявки"
              options={OPTIONS}
              {...field}
            />
          )}
        />

        <Text s="sub-headline-bold">Срок исполнения</Text>
        <Input s="m" placeholder="Дата начала" {...register('slaFrom')} />
        <Input s="m" placeholder="Дата окончания" {...register('slaTo')} />

        <Text s="sub-headline-bold">Дата создания</Text>
        <Input s="m" placeholder="Дата начала" {...register('createdFrom')} />
        <Input s="m" placeholder="Дата окончания" {...register('createdTo')} />
      </Flex>

      <Button variant="tertiary" s="l" onClick={() => reset()}>
        Сбросить фильтр
      </Button>
    </Flex>
  )
}
