import { ReactNode } from '@tanstack/react-router'

import { Color } from '../../types/colors'
import { Box, BoxProps } from '../box'
import { Text } from '../text'

interface FeatureValueProps extends BoxProps {
  title: ReactNode
  color?: Color
}

export const FeatureValue = ({
  title,
  children,
  color = 'text-900',
  ...boxProps
}: FeatureValueProps) => {
  return (
    <Box {...boxProps}>
      <Text s="footnote" color="text-300">
        {title}
      </Text>
      <Text s="sub-headline" mt="6" color={color}>
        {children}
      </Text>
    </Box>
  )
}
