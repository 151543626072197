import { withStyles } from '@bruitt/classnames'
import { keys, omit, pick } from 'rambda'

import { Box, BoxProps } from '../box'

import s from './grid.module.scss'

const sx = withStyles(s)

type Columns = '1' | '2' | '3' | '4' | '5' | '6'
type Gaps = '2' | '4' | '6' | '8' | '10' | '12' | '16' | '20' | '24' | '40'
type GridItems = 'start' | 'end' | 'center' | 'stretch'
type GridDisplay = 'grid' | 'inline-grid' | 'none'

interface GridModelProps {
  className?: string
  display?: GridDisplay
  ji?: GridItems
  ai?: GridItems
  pi?: GridItems
  cols?: Columns
  g?: Gaps
  cg?: Gaps
  rg?: Gaps
  // media sm
  display_sm?: GridDisplay
  ji_sm?: GridItems
  ai_sm?: GridItems
  pi_sm?: GridItems
  cols_sm?: Columns
  g_sm?: Gaps
  cg_sm?: Gaps
  rg_sm?: Gaps
  // media md
  display_md?: GridDisplay
  ji_md?: GridItems
  ai_md?: GridItems
  pi_md?: GridItems
  cols_md?: Columns
  g_md?: Gaps
  cg_md?: Gaps
  rg_md?: Gaps
  // media lg
  display_lg?: GridDisplay
  ji_lg?: GridItems
  ai_lg?: GridItems
  pi_lg?: GridItems
  cols_lg?: Columns
  g_lg?: Gaps
  cg_lg?: Gaps
  rg_lg?: Gaps
  // media xl
  display_xl?: GridDisplay
  ji_xl?: GridItems
  ai_xl?: GridItems
  pi_xl?: GridItems
  cols_xl?: Columns
  g_xl?: Gaps
  cg_xl?: Gaps
  rg_xl?: Gaps
}

type GridPropsKey = keyof GridModelProps

const GRID_PROPS: { [key in GridPropsKey]: true } = {
  className: true,
  display: true,
  ji: true,
  ai: true,
  pi: true,
  cols: true,
  g: true,
  cg: true,
  rg: true,
  // media sm
  display_sm: true,
  ji_sm: true,
  ai_sm: true,
  pi_sm: true,
  cols_sm: true,
  g_sm: true,
  cg_sm: true,
  rg_sm: true,
  // media md
  display_md: true,
  ji_md: true,
  ai_md: true,
  pi_md: true,
  cols_md: true,
  g_md: true,
  cg_md: true,
  rg_md: true,
  // media lg
  display_lg: true,
  ji_lg: true,
  ai_lg: true,
  pi_lg: true,
  cols_lg: true,
  g_lg: true,
  cg_lg: true,
  rg_lg: true,
  // media xl
  display_xl: true,
  ji_xl: true,
  ai_xl: true,
  pi_xl: true,
  cols_xl: true,
  g_xl: true,
  cg_xl: true,
  rg_xl: true,
}

const GRID_PROPS_KEYS = keys(GRID_PROPS)

export interface GridProps
  extends GridModelProps,
    Omit<
      BoxProps,
      'display' | 'display_sm' | 'display_md' | 'display_lg' | 'display_xl'
    > {}

// =========================

export const Grid = ({
  children,
  className,
  display = 'grid',
  ...props
}: GridProps) => {
  const gridProps = pick(GRID_PROPS_KEYS, props)
  const rest = omit(GRID_PROPS_KEYS, props)

  return (
    <Box className={sx(className, gridProps)} display={display} {...rest}>
      {children}
    </Box>
  )
}
