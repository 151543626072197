export const debounce = (fn: () => unknown, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>

  return function (this: unknown, ...args: unknown[]) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args as []), ms)
  }
}

export const debouncePromise = <Args, RType>(
  cb: (...args: Args[]) => RType,
  duration: number,
) => {
  let timeout: ReturnType<typeof setTimeout>
  return (...args: Args[]) =>
    new Promise<Awaited<RType>>((resolve) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        resolve(cb(...args) as Awaited<RType>)
      }, duration)
    })
}
