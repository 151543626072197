import { ReactElement, SVGProps } from 'react'

export const Pdf = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="6" y="2" width="16" height="20" rx="1.5" fill="#BB4B4B" />
    <path
      d="M1.38555 6.71875H16.2144C16.449 6.71856 16.674 6.81111 16.8401 6.97606C17.0061 7.14102 17.0996 7.36489 17.1 7.59847V16.4015C17.0996 16.6351 17.0061 16.859 16.8401 17.0239C16.674 17.1889 16.449 17.2814 16.2144 17.2813H1.38555C1.26939 17.2814 1.15433 17.2588 1.04695 17.2147C0.939567 17.1706 0.841966 17.1059 0.759725 17.0242C0.677484 16.9425 0.612215 16.8454 0.56765 16.7386C0.523085 16.6318 0.500097 16.5172 0.5 16.4015V7.59847C0.500097 7.48278 0.523085 7.36825 0.56765 7.26142C0.612215 7.15458 0.677484 7.05755 0.759725 6.97585C0.841966 6.89415 0.939567 6.82939 1.04695 6.78528C1.15433 6.74116 1.26939 6.71856 1.38555 6.71875Z"
      fill="url(#paint0_linear_2068_14)"
    />
    <path
      d="M2.4425 14.5909V9.5H4.45103C4.83715 9.5 5.16611 9.57375 5.43789 9.72124C5.70967 9.86707 5.91682 10.0701 6.05934 10.3303C6.20351 10.5888 6.2756 10.8871 6.2756 11.2251C6.2756 11.5632 6.20269 11.8615 6.05685 12.12C5.91102 12.3786 5.69973 12.5799 5.42297 12.7241C5.14788 12.8683 4.81478 12.9403 4.42368 12.9403H3.1435V12.0778H4.24968C4.45683 12.0778 4.62752 12.0421 4.76175 11.9709C4.89764 11.898 4.99873 11.7977 5.06502 11.6701C5.13296 11.5408 5.16694 11.3925 5.16694 11.2251C5.16694 11.0561 5.13296 10.9086 5.06502 10.7827C4.99873 10.6551 4.89764 10.5565 4.76175 10.4869C4.62586 10.4156 4.45351 10.38 4.24471 10.38H3.51885V14.5909H2.4425Z"
      fill="white"
    />
    <path
      d="M8.77942 14.5909H6.97473V9.5H8.79433C9.30641 9.5 9.74722 9.60192 10.1168 9.80575C10.4863 10.0079 10.7705 10.2988 10.9694 10.6783C11.1699 11.0578 11.2702 11.5118 11.2702 12.0405C11.2702 12.5708 11.1699 13.0265 10.9694 13.4077C10.7705 13.7888 10.4847 14.0813 10.1118 14.2852C9.74059 14.489 9.29646 14.5909 8.77942 14.5909ZM8.05108 13.6687H8.73467C9.05286 13.6687 9.32049 13.6123 9.53759 13.4996C9.75634 13.3853 9.9204 13.2088 10.0298 12.9702C10.1408 12.7299 10.1963 12.42 10.1963 12.0405C10.1963 11.6643 10.1408 11.3569 10.0298 11.1183C9.9204 10.8796 9.75717 10.704 9.54007 10.5913C9.32298 10.4786 9.05534 10.4222 8.73716 10.4222H8.05108V13.6687Z"
      fill="white"
    />
    <path
      d="M12.0675 14.5909V9.5H15.4382V10.3874H13.1439V11.6005H15.2145V12.4879H13.1439V14.5909H12.0675Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2068_14"
        x1="0.5"
        y1="6.5"
        x2="17"
        y2="17.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BE4C4C" />
        <stop offset="1" stopColor="#552626" />
      </linearGradient>
    </defs>
  </svg>
)
