import { withStyles } from '@bruitt/classnames'

import { Flex, FlexProps } from '../flex'

import s from './avatar-placeholder.module.scss'

const sx = withStyles(s)

interface AvatarPlaceholderProps extends FlexProps {
  children?: React.ReactNode
}

export const AvatarPlaceholder = ({
  children,
  className,
  ...rest
}: AvatarPlaceholderProps) => {
  return (
    <Flex
      jc="center"
      ai="center"
      bgColor="base-50"
      rad="6"
      className={sx(s.root, className)}
      {...rest}
    >
      {children}
    </Flex>
  )
}
