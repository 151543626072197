import { useMutation } from '@tanstack/react-query'

import { setAuthToken } from '../../../shared/lib/auth'
import { getErrorTextByError } from '../../../shared/lib/get-error-text-by-error'
import { login } from '../api'

interface useLoginMutationParams {
  onSuccess?: () => void
}

export const useLoginMutation = ({
  onSuccess,
}: useLoginMutationParams = {}) => {
  const { mutate, isPending, isSuccess, error } = useMutation({
    mutationFn: login,
    onSuccess: ({ accessToken }) => {
      setAuthToken(accessToken)
      onSuccess?.()
    },
  })

  return {
    isPending: isPending || isSuccess,
    login: mutate,
    errorText: getErrorTextByError(error),
  }
}
