import { Link, useRouterState } from '@tanstack/react-router'

import { usePopupNotifications } from '../../../../entities/notification/use-popup-notifications'
import { ROUTES } from '../../../../shared/constants/routes'
import { Box } from '../../../../shared/uikit/box'
import { Flex } from '../../../../shared/uikit/flex'
import { LinkButton } from '../../../../shared/uikit/link-button'
import { Text } from '../../../../shared/uikit/text'
import { ProfileBar } from '../../../profile-bar/components/profile-bar'
import { useMenuItems } from '../../use-menu-items'
import { MobileMenu } from '../mobile-menu'
import { MobileMenuItem } from '../mobile-menu-item'
import { SideMenuItem } from '../side-menu-item'

import s from './layout.module.scss'

interface LayoutProps {
  children?: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const {
    location: { pathname },
  } = useRouterState()
  const menuItems = useMenuItems()

  usePopupNotifications()

  return (
    <Box className={s.wrapper} bgColor="base-200">
      <Box
        as="aside"
        className={s.side}
        bgColor="base-500"
        pt="32"
        px="24"
        pb="20"
        hidden
        display_lg="block"
      >
        <Flex dir="column" className={s.sideContent}>
          <Flex jc="space-between" ai="center">
            <Flex dir="column">
              <Link to={ROUTES.index}>
                <img src="/logo-grey.svg" width={94} height={24} alt="logo" />
              </Link>
              <Text s="caption1" mt="8" color="base-0">
                Управляй вместе с нами
              </Text>
            </Flex>
            <img src="/257-logo.svg" width={70} height={64} alt="logo" />
          </Flex>

          <Flex dir="column" gr="1" py="32" g="8">
            {menuItems.map(({ title, icon, to }) => (
              <Link key={to} to={to} onClick={() => {}}>
                <SideMenuItem
                  title={title}
                  icon={icon}
                  isActive={
                    to === ROUTES.index
                      ? pathname === to
                      : pathname.includes(to)
                  }
                />
              </Link>
            ))}
          </Flex>

          <Flex ai="center" dir="column" g="16">
            <a
              href="https://t.me/renda_realestate"
              target="_blank"
              rel="noreferrer"
            >
              <LinkButton s="m" pre="telegram-2-filled">
                Telegram
              </LinkButton>
            </a>
          </Flex>
        </Flex>
      </Box>
      <Flex
        className={s.topBar}
        hidden_lg
        bgColor="base-500"
        jc="space-between"
        ai="center"
        px="20"
      >
        <Flex g="24" ai="center">
          <img src="/257-logo.svg" width={49} height={45} alt="logo" />
          <Link to={ROUTES.index}>
            <img src="/logo-grey.svg" width={71} height={18} alt="logo" />
          </Link>
        </Flex>
        <ProfileBar />
      </Flex>

      <Box className={s.main} as="main" p_md="32">
        {children}
      </Box>

      <MobileMenu>
        {menuItems.map(({ title, icon, to }) => (
          <Link key={to} to={to} onClick={() => {}}>
            <MobileMenuItem
              title={title}
              icon={icon}
              isActive={
                to === ROUTES.index ? pathname === to : pathname.includes(to)
              }
            />
          </Link>
        ))}
      </MobileMenu>
    </Box>
  )
}
