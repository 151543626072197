import { useCallback, useState } from 'react'

export const useBoolean = (initial: boolean) => {
  if (typeof initial !== 'boolean') {
    throw new Error('initial must be `true` or `false`')
  }

  const [isTrue, setValue] = useState(initial)

  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])
  const toggle = useCallback(() => setValue((p) => !p), [])

  return {
    isTrue,
    isFalse: !isTrue,
    setTrue,
    setFalse,
    toggle,
    setValue,
  }
}
