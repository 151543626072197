import { withStyles } from '@bruitt/classnames'

import { BaseModal, BaseModalProps } from '../base-modal'

import s from './fullscreen-modal.module.scss'

const sx = withStyles(s)

interface FullscreenModalProps extends BaseModalProps {}

export const FullscreenModal = ({
  className,
  overlayClassName,
  ...rest
}: FullscreenModalProps) => {
  return (
    <BaseModal
      className={sx(s.root, className)}
      overlayClassName={sx(s.overlay, overlayClassName)}
      {...rest}
    />
  )
}
