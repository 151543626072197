import { useState } from 'react'

import { useModal } from '../../../shared/hooks/use-modal'

import { useChangeOrderStatusMutation } from './use-change-order-status-mutation'
import { useConfirmChangeOrderStatusMutation } from './use-confirm-order-status-mutation'

export const useChangeOrderStatus = (orderId: string) => {
  const [status, setStatus] = useState('')
  const [code, setCode] = useState<string[]>([])

  const onChangeSuccess = (isOtpRequired: boolean, status: string) => {
    if (isOtpRequired) {
      confirmModal.open()
      setStatus(status)
    }
  }

  const onModalClose = () => {
    setCode([])
    setStatus('')
    confirmModal.close()
  }

  const { isPending: isPendingChangeStatus, changeOrderStatus } =
    useChangeOrderStatusMutation({ orderId, onSuccess: onChangeSuccess })
  const { isPending: isPendingConfirmStatus, confirmChangeOrderStatus } =
    useConfirmChangeOrderStatusMutation({
      orderId,
      status,
      onSuccess: onModalClose,
    })

  const confirmModal = useModal()

  return {
    code,
    setCode,
    newStatus: status,
    confirmModal,
    onModalClose,
    isPendingChangeStatus,
    isPendingConfirmStatus,
    changeOrderStatus,
    confirmChangeOrderStatus,
  }
}
