interface FilterOptionsProps<T> {
  value: string
  labelName: keyof T
  options: T[]
}

export const filterOptions = <T>({
  value,
  labelName,
  options,
}: FilterOptionsProps<T>) => {
  const valueLowerCase = value.toLowerCase().replace(/\s/g, '')

  return options.filter((option) =>
    String(option?.[labelName])
      .toLowerCase()
      .replace(/\s/g, '')
      .includes(valueLowerCase),
  )
}

export const getInputValueFromOption = <T>(
  value: T | T[] | undefined | null,
  labelName: keyof T,
): string =>
  Array.isArray(value)
    ? value.map((v) => v[labelName]).join(', ')
    : ((value?.[labelName] ?? '') as string)
