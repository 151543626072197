import { FilePreview } from '../../../../shared/uikit/file-preview'
import { useOrderFile } from '../../hooks/use-order-file'

interface OrderFilePreviewProps {
  orderId: string
  fieldId: string
  fileName: string
  // isReadOnly?: boolean
}

export const OrderFilePreview = ({
  orderId,
  fieldId,
  fileName,
  // isReadOnly = false,
}: OrderFilePreviewProps) => {
  const { photoUrl, isError, refetch } = useOrderFile({
    orderId,
    fieldId,
    fileName,
  })

  return photoUrl ? (
    <a href={photoUrl} target="_blank" rel="noreferrer">
      <FilePreview
        fileName={fileName}
        isError={isError}
        url={photoUrl}
        // onDelete={isReadOnly ? undefined : deleteOrderFile}
        onRefetch={refetch}
      />
    </a>
  ) : (
    <FilePreview
      fileName={fileName}
      isError={isError}
      url={photoUrl}
      // onDelete={isReadOnly ? undefined : deleteOrderFile}
      onRefetch={refetch}
    />
  )
}
