import { Profile as ProfileType } from '../../../../entities/user/types'
import { formatPhone } from '../../../../shared/lib/format-phone'
import { getFullName } from '../../../../shared/lib/get-full-name'
import { AvatarPlaceholder } from '../../../../shared/uikit/avatar-placeholder'
import { Box } from '../../../../shared/uikit/box'
import { FeatureValue } from '../../../../shared/uikit/feature-value'
import { Flex } from '../../../../shared/uikit/flex'
import { IconButton } from '../../../../shared/uikit/icon-button'
import { LinkButton } from '../../../../shared/uikit/link-button'
import { Text } from '../../../../shared/uikit/text'

import s from './profile.module.scss'

interface ProfileProps {
  onClose?: () => void
  onClickExit?: () => void
  profile?: ProfileType
  // user?: User
  photoUrl?: string
}

export const Profile = ({
  // user,
  profile,
  photoUrl,
  onClose,
  onClickExit,
}: ProfileProps) => {
  const fullName = getFullName(profile)

  return (
    <Box className={s.root} rad="20" bgColor="base-0">
      <Box className={s.top} bgColor="primary-500" />
      {photoUrl ? (
        <img
          className={s.avatar}
          src={photoUrl}
          width={110}
          height={110}
          alt="avatar"
        />
      ) : (
        <AvatarPlaceholder className={s.avatar} />
      )}
      <IconButton
        className={s.closeButton}
        variant="secondary"
        s="l"
        icon="close"
        onClick={onClose}
      />

      <Box px="16" py="32">
        <Text s="body-bold" mt="12" align="center">
          {fullName}
        </Text>
        <Text s="body" mt="6" align="center">
          {formatPhone(profile?.phone)}
        </Text>

        <Flex mt="32" px="16" g="16" dir="column">
          <FeatureValue title="Роль">
            {profile?.legalEntity.jobPositionName || '-'}
          </FeatureValue>
        </Flex>

        <Box className={s.divider} my="20" />

        <Box px="16">
          <LinkButton
            s="l"
            variant="secondary"
            pre="logout-box-r"
            onClick={onClickExit}
          >
            Выйти
          </LinkButton>
        </Box>
      </Box>
    </Box>
  )
}
