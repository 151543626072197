import { bffRequest } from '../../shared/api/bff-request'
import {
  ListResponse,
  PaginationRequestParams,
  SortingRequestParams,
} from '../../shared/types/api-types'

import { Order, OrderListItem, OrderSortColumn } from './types'

export const getOrder = (id: string) =>
  bffRequest.get<Order>(`/Orders/GetOrder?id=${id}`)

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetOrdersFilter {}

interface GetOrdersRequest extends GetOrdersFilter {
  pagination: PaginationRequestParams
  sorting?: SortingRequestParams<OrderSortColumn>
}

export const getOrders = (body: GetOrdersRequest) =>
  bffRequest.post<ListResponse<OrderListItem>>('/Orders/GetList', body)

export interface AddOrderFilesRequest {
  OrderId: string
  FieldId: string
  Files: File[]
}

export const addOrderFiles = (data: AddOrderFilesRequest) =>
  bffRequest.post('/Orders/AddOrderFiles', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

export interface GetOrderFileParams {
  orderId: string
  fieldId: string
  fileName: string
}

export const getOrderFile = ({
  orderId,
  fieldId,
  fileName,
}: GetOrderFileParams) =>
  bffRequest
    .get<Blob>(
      `/Orders/GetOrderFile?orderId=${orderId}&fieldId=${fieldId}&fileName=${fileName}`,
      { responseType: 'blob' },
    )
    .then((blob) => URL.createObjectURL(blob))

export interface ChangeOrderStatusRequest {
  orderId: string
  status: string
}

interface ChangeOrderStatusResponse {
  otpConfirmationRequired: boolean
}

export const changeOrderStatus = (body: ChangeOrderStatusRequest) =>
  bffRequest
    .post<ChangeOrderStatusResponse>('/Orders/ChangeOrderStatus', body)
    .then((response) => ({
      ...response,
      ...body,
    }))

export interface ConfirmChangeOrderStatusRequest {
  orderId: string
  status: string
  otpCode: string
}

export const confirmChangeOrderStatus = (
  body: ConfirmChangeOrderStatusRequest,
) => bffRequest.post('/Orders/ConfirmOrderStatusChange', body)
