import { withStyles } from '@bruitt/classnames'

import { Box, BoxProps } from '../box'
import { Flex, FlexProps } from '../flex'

import s from './card.module.scss'

const sx = withStyles(s)

interface CardProps extends BoxProps {
  children?: React.ReactNode
}

const Card = ({ children, ...rest }: CardProps) => {
  return (
    <Box rad="16" rad_md="24" bgColor="base-0" {...rest}>
      {children}
    </Box>
  )
}

interface HeaderProps extends FlexProps {
  children?: React.ReactNode
}

const Header = ({ children, className, ...rest }: HeaderProps) => {
  return (
    <Flex
      className={sx(s.header, className)}
      pl="24"
      py="20"
      pr="16"
      ai="center"
      {...rest}
    >
      {children}
    </Flex>
  )
}

interface BodyProps extends BoxProps {
  children?: React.ReactNode
}

const Body = ({ children, ...rest }: BodyProps) => {
  return (
    <Box p="24" {...rest}>
      {children}
    </Box>
  )
}

interface DividerProps extends BoxProps {}

const Divider = ({ className, ...rest }: DividerProps) => {
  return <Box className={sx(s.divider, className)} {...rest} />
}

const CardNamespace = Object.assign(Card, { Header, Body, Divider })

export { CardNamespace as Card }
