import { AxiosError, InternalAxiosRequestConfig } from 'axios'

import { router } from '../../main'
import { ROUTES } from '../constants/routes'
import { getAuthToken, removeAuthToken } from '../lib/auth'

export const requestHook = (config: InternalAxiosRequestConfig) => {
  try {
    const token = getAuthToken()

    if (config.headers) config.headers.Authorization = `Bearer ${token}`

    return config
  } catch (e) {
    return config
  }
}

export const responseErrorHook = async (error: AxiosError) => {
  if (
    error.response?.status === 401 &&
    router.latestLocation.href !== ROUTES.login
  ) {
    removeAuthToken()

    void router.navigate({
      to: ROUTES.login,
      search: {
        redirect: router.latestLocation.href,
      },
    })
  }

  return Promise.reject(error)
}
