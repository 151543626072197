import { z } from 'zod'

import { ORDER_LIST_SORT_FIELDS } from '../../entities/order/constants'
import { zodEnum } from '../../shared/lib/zod-enum'
import { SORT_DIRECTIONS } from '../../shared/types/sort'

export const ordersListParamsSchema = z.object({
  sorting: z
    .enum(zodEnum(Object.values(ORDER_LIST_SORT_FIELDS)))
    .optional()
    .catch(undefined),
  sortingDir: z
    .enum(zodEnum(Object.values(SORT_DIRECTIONS)))
    .optional()
    .catch(undefined),
})

export type OrdersListSearch = z.infer<typeof ordersListParamsSchema>
