import { withStyles } from '@bruitt/classnames'

import { Flex } from '../../../../shared/uikit/flex'
import { Icon, IconName } from '../../../../shared/uikit/icon'
import { Text } from '../../../../shared/uikit/text'

import s from './side-menu-item.module.scss'

const sx = withStyles(s)

interface SideMenuItemProps {
  title: string
  icon: IconName
  isActive?: boolean
}

export const SideMenuItem = ({ title, icon, isActive }: SideMenuItemProps) => {
  return (
    <button type="button" className={sx(s.button, { isActive })}>
      <Flex py="12" pl="8" pr="16" ai="center" g="12">
        <Icon name={icon} s="20" className={s.icon} />
        <Text s="sub-headline" className={s.text}>
          {title}
        </Text>
      </Flex>
    </button>
  )
}
