import { notify, NotifyAlignment } from 'react-simple-notify'

import { NotificationPopup } from '../../entities/notification/components/notification-popup'
import { PageHeader } from '../../shared/components/page-header'
import { useResponsive } from '../../shared/hooks/use-responsive'
import { Box } from '../../shared/uikit/box'
import { Button } from '../../shared/uikit/button'
import { Flex } from '../../shared/uikit/flex'
import { ProfileBar } from '../../widgets/profile-bar/components/profile-bar'

export const IndexPage = () => {
  const isMobile = useResponsive('md')

  return (
    <Box px="20" px_md="0" pt="20" pt_md="0">
      <Flex jc="space-between">
        <PageHeader>Дашборд</PageHeader>
        <Box hidden display_lg="block">
          <ProfileBar />
        </Box>
      </Flex>
      <Box mt="24">
        <Button
          variant="primary"
          s="m"
          onClick={() => {
            notify.open({
              duration: 6_000,
              alignment: isMobile
                ? NotifyAlignment.bottomRight
                : NotifyAlignment.topRight,
              // @ts-ignore
              render: ({ onClose }) => (
                <NotificationPopup
                  icon="Information"
                  title="Назначен исполнитель на заявку"
                  text="Произошло задымление на первом этаже, в помещении номер 1, где находится магазин товаров для взрослых «Валера»"
                  onClose={onClose}
                />
              ),
            })
          }}
        >
          Показать уведомление
        </Button>
      </Box>
    </Box>
  )
}
