import { z } from 'zod'

export interface Option<T = string> {
  label: string
  value: T
  disabled?: boolean
}

export const optionSchema = {
  label: z.string(),
  value: z.string(),
  disabled: z.boolean().optional(),
}
