import { getRouteApi } from '@tanstack/react-router'
import { useEffect, useState } from 'react'

import { useLoginMutation } from '../../entities/user/hooks/use-login-mutation'
import { useSendOtpMutation } from '../../entities/user/hooks/use-send-otp-mutation'

import { LOGIN_STEPS, SMS_RETRY_TIMEOUT_SEC } from './constants'
import { LoginSearchParams, LoginStep } from './types'

const { useSearch, useNavigate } = getRouteApi('/login')

export const useLoginPage = () => {
  const { step = LOGIN_STEPS.phone, redirect = '/' } = useSearch()
  const navigate = useNavigate()

  const [phone, setPhone] = useState<string>('')
  const [phoneError, setPhoneError] = useState('')
  const [code, setCode] = useState<string[]>([])
  const [codeError, setCodeError] = useState('')

  const [counter, setCounter] = useState(0)

  useEffect(() => {
    if (counter > 0 && step === LOGIN_STEPS.code) {
      setTimeout(() => setCounter(counter - 1), 1000)
    }
  }, [counter, step])

  const sendOtpMutation = useSendOtpMutation({
    onSuccess: () => {
      handleChangeStep(LOGIN_STEPS.code)
      setCounter(SMS_RETRY_TIMEOUT_SEC)
      setCode([])
      setCodeError('')
    },
  })

  const loginMutation = useLoginMutation({
    onSuccess: () => {
      void navigate({ to: redirect })
    },
  })

  const handleChangeParams = (newSearch: LoginSearchParams) => {
    void navigate({
      search: (prev) => ({ ...prev, ...newSearch }),
      replace: true,
    })
  }

  const handleChangeStep = (step: LoginStep) => handleChangeParams({ step })

  const handleSubmitPhone = () => {
    setPhoneError('')

    if (phone.trim() === '') {
      setPhoneError('Введите номер телефона')
    } else if (phone.trim().length !== 12) {
      setPhoneError('Введите корректный номер телефона')
    } else {
      sendOtpMutation.sendOtp({ phone: `+7${phone.replace(/\D/g, '')}` })
    }
  }

  const handleSubmitCode = () => {
    setCodeError('')

    if (code.filter(Boolean).length === 0) {
      setCodeError('Введите код полностью')
    } else if (code.filter(Boolean).length !== 4) {
      setCodeError('Введите код полностью')
    } else {
      if (!phone) handleChangeStep(LOGIN_STEPS.phone)

      loginMutation.login({
        phone: `+7${phone.replace(/\D/g, '')}`,
        code: code.join(''),
      })
    }
  }

  return {
    flags: {
      isPendingSendOtp: sendOtpMutation.isPending,
      isPendingLogin: loginMutation.isPending,
    },
    data: {
      step,
      code,
      phone,
      counter,
      codeError,
      phoneError,
      sendOtpError: sendOtpMutation.errorText,
      loginError: loginMutation.errorText,
    },
    actions: {
      handleChangeStep,
      handleChangeCode: setCode,
      handleChangePhone: setPhone,
      handleSubmitPhone,
      handleSubmitCode,
    },
  }
}
