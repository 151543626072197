import { Option } from '../../shared/types/option'

import { StatusOption } from './types-schema'

export const ORDER_STATUSES_OPTIONS: StatusOption[] = [
  { label: 'Черновик', value: 'Draft' },
  { label: 'Новая', value: 'New' },
  { label: 'В работе', value: 'InProgress' },
  { label: 'Завершено', value: 'Completed' },
  { label: 'Отменено', value: 'Canceled' },
]

export const ORDER_USER_ROLES_MAP = {
  Employee: 'Employee',
  Admin: 'Admin',
  Renter: 'Renter',
  Owner: 'Owner',
} as const

export const ORDER_USER_ROLES_OPTIONS: Option[] = Object.entries(
  ORDER_USER_ROLES_MAP,
).map(([value, label]) => ({ label, value }))

export const ORDER_USER_ROLES = Object.values(ORDER_USER_ROLES_MAP)

export const ORDER_FIELD_PERMISSIONS_MAP = {
  visible: 'visible',
  canEdit: 'canEdit',
  required: 'required',
} as const

export const ORDER_FIELD_PERMISSIONS = Object.values(
  ORDER_FIELD_PERMISSIONS_MAP,
)

export const ORDER_FIELD_TYPES = {
  text: 'Text',
  multilineText: 'MultilineText',
  date: 'Date',
  time: 'Time',
  dateTime: 'DateTime',
  phone: 'Phone',
  singleSelect: 'SingleSelect',
  multiSelect: 'MultiSelect',
  fileUpload: 'FileUpload',
  photoUpload: 'PhotoUpload',
} as const
