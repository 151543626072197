import { PageHeader } from '../../shared/components/page-header'
import { Box } from '../../shared/uikit/box'
import { Flex } from '../../shared/uikit/flex'
import { ProfileBar } from '../../widgets/profile-bar/components/profile-bar'

export const RentAreasPage = () => {
  return (
    <Box px="20" px_md="0" pt="20" pt_md="0">
      <Flex jc="space-between">
        <PageHeader>Помещения</PageHeader>
        <Box hidden display_lg="block">
          <ProfileBar />
        </Box>
      </Flex>
    </Box>
  )
}
