import { withStyles } from '@bruitt/classnames'
import React, { forwardRef, ReactNode, useRef } from 'react'

import style from './input-wrapper.module.scss'

const sx = withStyles(style)

export interface InputWrapperProps {
  isError?: boolean
  pre?: ReactNode
  post?: ReactNode
  disabled?: boolean
  className?: string
  placeholder?: string
  inputBoxClassName?: string
  name?: string
  s?: 'm' | 'xl'
  isSelect?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  isNumberInput?: boolean // pass it only when wrap react-number-format components with InputWrapper
  children?: ReactNode
}

export const InputWrapper = forwardRef<HTMLInputElement, InputWrapperProps>(
  (
    {
      isError = false,
      pre,
      post,
      disabled = false,
      className,
      placeholder,
      inputBoxClassName,
      name,
      onClick,
      s = 'xl',
      isSelect,
      isNumberInput = false,
      children,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      // @ts-ignore
      ref?.current?.focus?.()
      inputRef.current?.focus?.()
      onClick?.(event)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleMouseDown = (event: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (!['INPUT', 'SELECT', 'TEXTAREA'].includes(event.target.tagName)) {
        event.preventDefault()
      }
    }

    const myRef = isNumberInput
      ? () => ({
          getInputRef: (node: HTMLInputElement) => {
            inputRef.current = node
          },
        })
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (child: any) => ({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref: (node: any) => {
            inputRef.current = node

            // @ts-ignore
            if (typeof child?.ref === 'function') child.ref?.(node)
          },
        })

    return (
      <div
        className={sx(style.root, style.variant, className, {
          s,
          isError,
          disabled,
          isSelect,
        })}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
      >
        {pre && <span className={style.enhancer}>{pre}</span>}

        <div className={sx(style.inputBox, inputBoxClassName)}>
          {React.Children.map(
            children,
            (child) =>
              React.isValidElement(child) &&
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              React.cloneElement(child as React.ReactElement<any>, {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                className: sx(style.input, child?.props?.className, {
                  isPre: Boolean(pre),
                  isPost: Boolean(post) || isError,
                }),
                onBlur: (
                  event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>,
                ) => {
                  // @ts-ignore

                  ref?.current?.blur?.()
                  // @ts-ignore

                  child.props?.onBlur?.(event)
                },
                onFocus: (
                  event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>,
                ) => {
                  // @ts-ignore

                  ref?.current?.focus?.()
                  // @ts-ignore

                  child.props?.onFocus?.(event)
                },
                ...myRef(child),
              }),
          )}

          <label
            htmlFor={name}
            className={sx(style.placeholder, {
              isPre: Boolean(pre),
              isPost: Boolean(post) || isError,
            })}
          >
            {placeholder}
          </label>
        </div>

        {post && <span className={style.enhancer}>{post}</span>}
      </div>
    )
  },
)

InputWrapper.displayName = 'InputWrapper'
