import { z } from 'zod'

import { optionSchema } from '../../shared/types/option'

import { ORDER_FIELD_TYPES } from './constants-schema'

const commonFieldSchema = z.object({
  id: z.number(),
  label: z.string(),
  placeholder: z.string(),
})

const textSchema = commonFieldSchema.extend({
  type: z.literal(ORDER_FIELD_TYPES.text),
})

const multilineTextSchema = commonFieldSchema.extend({
  type: z.literal(ORDER_FIELD_TYPES.multilineText),
})

const dateSchema = commonFieldSchema.extend({
  type: z.literal(ORDER_FIELD_TYPES.date),
})

const timeSchema = commonFieldSchema.extend({
  type: z.literal(ORDER_FIELD_TYPES.time),
})

const dateTimeSchema = commonFieldSchema.extend({
  type: z.literal(ORDER_FIELD_TYPES.dateTime),
})

const phoneSchema = commonFieldSchema.extend({
  type: z.literal(ORDER_FIELD_TYPES.phone),
})

const singleSelectSchema = commonFieldSchema.extend({
  type: z.literal(ORDER_FIELD_TYPES.singleSelect),
  options: z.array(z.object(optionSchema)),
})

const multiSelectSchema = commonFieldSchema.extend({
  type: z.literal(ORDER_FIELD_TYPES.multiSelect),
  options: z.array(z.object(optionSchema)),
})

const fileUploadSchema = commonFieldSchema.extend({
  type: z.literal(ORDER_FIELD_TYPES.fileUpload),
  maxFilesNumber: z.number(),
})

const photoUploadSchema = commonFieldSchema.extend({
  type: z.literal(ORDER_FIELD_TYPES.photoUpload),
  maxFilesNumber: z.number(),
})

export const orderFieldSchema = z.discriminatedUnion('type', [
  textSchema,
  multilineTextSchema,
  dateSchema,
  timeSchema,
  dateTimeSchema,
  phoneSchema,
  singleSelectSchema,
  multiSelectSchema,
  fileUploadSchema,
  photoUploadSchema,
])

export type OrderSettingsField = z.infer<typeof orderFieldSchema>

export type OrderSettingsFieldType = OrderSettingsField['type']

export interface PermissionField {
  id: number
  label: string
}

export interface FieldPermissions {
  // status
  [key: string]: {
    // role
    [key: string]: {
      // field ids
      [key: string]: number[]
    }
  }
}

export interface ColumnSelections {
  // status
  [key: string]: {
    // role
    [key: string]: {
      // permission
      [key: string]: boolean
    }
  }
}

export interface LineSelections {
  [key: string]: {
    [key: string]: boolean
  }
}

export type StatusTransitions = {
  // from status
  [key: string]: {
    // role
    [key: string]: {
      // to status
      [key: string]: boolean
    }
  }
}

const orderSettingsLayoutSchema = z.array(
  z.object({
    title: z.string(),
    columns: z.number(),
    fields: z.array(
      z.object({
        id: z.number(),
        span: z.number(),
      }),
    ),
  }),
)

export type OrderSettingsLayout = z.infer<typeof orderSettingsLayoutSchema>

const rolePermissionSchema = z.object({
  visible: z.array(z.number()),
  canEdit: z.array(z.number()),
  required: z.array(z.number()),
})

export type OrderRolePermission = z.infer<typeof rolePermissionSchema>

export const orderSettingsStatusesSchema = z.array(
  z.object({
    statusCode: z.string(),
    statusTitle: z.string(),
    plannedCompletionMinutes: z.number().optional(),
    deadlineNotificationMinutes: z.number().optional(),
    roleTransitions: z.array(
      z.object({
        role: z.string(),
        transitions: z.array(
          z.object({
            statusCode: z.string(),
            actionTitle: z.string(),
            otpConfirmationRequired: z.boolean().optional(),
          }),
        ),
      }),
    ),
    rolePermissions: z.array(
      z.object({
        role: z.string(),
        permission: rolePermissionSchema,
      }),
    ),
  }),
)

export type OrderSettingsStatuses = z.infer<typeof orderSettingsStatusesSchema>

export const orderFieldsSchema = z.array(orderFieldSchema)

export const orderSettingsFormSchema = z.object({
  fields: orderFieldsSchema,
  layoutDetails: orderSettingsLayoutSchema,
  layoutProcess: orderSettingsLayoutSchema,
})

export const orderSettingsSchema = orderSettingsFormSchema.extend({
  statuses: orderSettingsStatusesSchema,
})

export const orderSettingsEditorSchema = orderSettingsFormSchema.extend({
  statuses: orderSettingsStatusesSchema.optional(),
})

export type OrderSettings = z.infer<typeof orderSettingsSchema>
export type OrderSettingsEditor = z.infer<typeof orderSettingsEditorSchema>

export type OrderSettingsFormValues = z.infer<typeof orderSettingsFormSchema>

export const statusOption = z.object({
  label: z
    .string({
      invalid_type_error: 'Обязательное поле',
      required_error: 'Обязательное поле',
    })
    .min(1, 'Обязательное поле'),
  value: z
    .string({
      invalid_type_error: 'Обязательное поле',
      required_error: 'Обязательное поле',
    })
    .min(1, 'Обязательное поле'),
  plannedCompletionMinutes: z.string().optional(),
  deadlineNotificationMinutes: z.string().optional(),
})

export type StatusOption = z.infer<typeof statusOption>

export const schemaStatusesFormSchema = z.object({
  statuses: z.array(statusOption),
})

export const orderStatusesSchema = z.object({
  statuses: orderSettingsStatusesSchema,
})

export type SchemaStatusesFormValues = z.infer<typeof schemaStatusesFormSchema>

export interface CustomFieldsFormValues {
  [key: string]: string[]
}

export interface CustomFieldsFilesValues {
  [key: string]: File[]
}
