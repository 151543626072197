import { parseDate } from '../../../../shared/lib/dates'
import { Box } from '../../../../shared/uikit/box'
import { Card } from '../../../../shared/uikit/card'
import { Grid } from '../../../../shared/uikit/grid'
import { Tag } from '../../../../shared/uikit/tag'
import { Text } from '../../../../shared/uikit/text'
import { OrderListItem } from '../../types'

interface OrderCardProps {
  order: OrderListItem
}

export const OrderCard = ({ order: o }: OrderCardProps) => {
  return (
    <Card p="16">
      <Tag s="s" bgColor="base-300">
        {o.status.title}
      </Tag>
      <Text s="body-bold" mt="16">
        {o.typeName}. {o.subtypeName}
      </Text>
      <Text s="caption1" color="text-500" mt="4">
        {o.projectName} {o.address?.city}
      </Text>
      <Grid cols="2" g="12" mt="16">
        <Box>
          <Text s="caption1" color="text-300">
            Помещение
          </Text>
          <Text s="footnote">{o.rentAreaName}</Text>
        </Box>
        <Box>
          <Text s="caption1" color="text-300">
            Срок исполнения
          </Text>
          <Text s="footnote">
            {parseDate(o.plannedCompletionDate, 'dd.MM.yyyy в HH:mm')}
          </Text>
        </Box>
      </Grid>
      <Grid cols="2" g="12" mt="16">
        <Text s="caption1" color="text-300">
          № {o.number.toString().padStart(7, '0')}
        </Text>
        <Text s="caption1" color="text-300">
          {parseDate(o.createdDate, 'dd.MM.yyyy в HH:mm')}
        </Text>
      </Grid>
    </Card>
  )
}
