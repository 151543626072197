import { withStyles } from '@bruitt/classnames'

import { ColorProps } from '../../types/colors'
import { IconSizesProps } from '../../types/icons'

import { ICON_TYPE_MAP } from './icon-type-map'

import styles from './icon.module.scss'

const sx = withStyles(styles)

type ElementProps = Omit<JSX.IntrinsicElements['svg'], 'color'>
interface IconProps extends ElementProps, IconSizesProps, ColorProps {
  name: IconName
}

export const Icon = ({
  name,
  className,
  s = '24',
  color,
  ...rest
}: IconProps) => {
  const Component = ICON_TYPE_MAP[name]

  return (
    <Component
      className={sx(className, { color })}
      {...rest}
      width={s}
      height={s}
    />
  )
}

export type IconName = keyof typeof ICON_TYPE_MAP
