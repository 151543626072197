import { useMutation, useQueryClient } from '@tanstack/react-query'

import { changeOrderStatus } from '../api'
import { ORDER_LIST_QUERY_KEY, ORDER_QUERY_KEY } from '../constants'

interface UseChangeOrderStatusParams {
  orderId: string
  onSuccess?: (isOtpRequired: boolean, status: string) => void
}

export const useChangeOrderStatusMutation = ({
  orderId,
  onSuccess,
}: UseChangeOrderStatusParams) => {
  const qc = useQueryClient()
  const { isPending, mutate } = useMutation({
    mutationFn: (status: string) => changeOrderStatus({ orderId, status }),
    onSuccess: ({ otpConfirmationRequired, status }) => {
      void qc.refetchQueries({
        queryKey: [ORDER_LIST_QUERY_KEY],
      })
      void qc.invalidateQueries({
        queryKey: [ORDER_QUERY_KEY, orderId],
      })
      onSuccess?.(otpConfirmationRequired, status)
    },
  })

  return {
    isPending: isPending,
    changeOrderStatus: mutate,
  }
}
