import { Color } from '../../../../shared/types/colors'
import { Tag, TagProps } from '../../../../shared/uikit/tag'
import { OrderStatus } from '../../types'

interface OrderStatusTagProps {
  status: OrderStatus
  s?: TagProps['s']
}

export const OrderStatusTag = ({ s = 's', status }: OrderStatusTagProps) => {
  return (
    <Tag
      s={s}
      color={COLORS[status.code] || 'base-0'}
      bgColor={BG_COLORS[status.code] || 'text-500'}
    >
      {status.title}
    </Tag>
  )
}

const COLORS: { [key: string]: Color } = {
  New: 'text-900',
  InProgress: 'text-900',
  Completed: 'text-900',
  Canceled: 'base-0',
}

const BG_COLORS: { [key: string]: Color } = {
  New: 'red-50',
  InProgress: 'blue-50',
  Completed: 'green-50',
  Canceled: 'text-200',
}
