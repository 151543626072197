import { withStyles } from '@bruitt/classnames'
import { FormEvent, KeyboardEvent, useCallback, useEffect, useRef } from 'react'

import { Flex } from '../flex'

import s from './input-otp.module.scss'

const sx = withStyles(s)

const CODE_LENGTH = 4
const codeMaxIndex = CODE_LENGTH - 1
const inputIndexes = Array.from({ length: CODE_LENGTH }, (_, i) => i)
const getFieldIndex = (field: HTMLInputElement) =>
  Number(field.name.replace(/\D/g, ''))

interface InputOtpProps {
  value: string[]
  onChange: (value: string[]) => void
  isDisabled?: boolean
  isError?: boolean
  inputClassName?: string
}

export const InputOtp = ({
  value,
  onChange,
  isDisabled = false,
  isError = false,
  inputClassName,
}: InputOtpProps) => {
  const field0Ref = useRef<HTMLInputElement>(null)
  const field1Ref = useRef<HTMLInputElement>(null)
  const field2Ref = useRef<HTMLInputElement>(null)
  const field3Ref = useRef<HTMLInputElement>(null)

  const getFieldRefByIndex = (fieldIndex: number) => {
    switch (fieldIndex) {
      case 0:
        return field0Ref
      case 1:
        return field1Ref
      case 2:
        return field2Ref
      case 3:
        return field3Ref
      default:
        return field0Ref
    }
  }
  const focusField = useCallback((fieldIndex: number) => {
    getFieldRefByIndex(fieldIndex).current?.focus()
  }, [])

  const onCodeKeyDown = ({
    currentTarget: field,
    key,
  }: KeyboardEvent<HTMLInputElement>) => {
    const fieldIndex = getFieldIndex(field)
    if (fieldIndex > 0 && key === 'Backspace' && field.value === '') {
      focusField(fieldIndex - 1)
    }
  }

  const onCodeChange = ({
    currentTarget: field,
  }: FormEvent<HTMLInputElement>) => {
    field.value = field.value.replace(/\D/g, '')
    const fieldIndex = getFieldIndex(field)
    if (field.value.length > 1) {
      field.value = field.value.slice(-1)
    }
    const newValue = [...value]
    newValue[fieldIndex] = field.value
    onChange(newValue)
    if (fieldIndex < codeMaxIndex && field.value) {
      focusField(fieldIndex + 1)
    }
  }

  useEffect(() => {
    if (value.length === 0) {
      focusField(0)
    }
  }, [value, focusField])

  return (
    <Flex g="8">
      {inputIndexes.map((index) => (
        <input
          key={index}
          className={sx(s.input, inputClassName, { isError, isDisabled })}
          min={0}
          max={9}
          size={1}
          step={1}
          inputMode="numeric"
          ref={getFieldRefByIndex(index)}
          name={`inputCode-${index}`}
          onChange={onCodeChange}
          onKeyDown={onCodeKeyDown}
          disabled={isDisabled}
          autoComplete="one-time-code"
          onInput={(e) => {
            const value = e.currentTarget.value

            if (value.length === 4) {
              e.preventDefault()
              const newValue = value.replace(/\D/g, '').split('').slice(0, 4)

              setTimeout(() => {
                onChange(newValue)
                focusField(newValue.length - 1)
              }, 0)
            }
          }}
          type="text"
          value={value[index] ?? ''}
        />
      ))}
    </Flex>
  )
}
