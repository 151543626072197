import { FocusEvent, useEffect, useRef, useState } from 'react'

import { filterOptions, getInputValueFromOption } from './lib'
import { SelectProps } from './select'
import { DefaultOption } from './types'

interface UseSelectRestParams<T> {
  disabled?: SelectProps<T>['disabled']
  onBlur?: SelectProps<T>['onBlur']
  valueName: keyof T
  labelName: keyof T
  footer?: SelectProps<T>['footer']
  readOnly?: SelectProps<T>['readOnly']
}

type UseSelectParams<T> =
  | (UseSelectRestParams<T> & {
      type: 'single'
      value?: T | null
      propsOptions?: T[]
      onChange?: (option: T | null, isCreated: boolean) => void
      isSearchable: boolean
      loadOptions?: (query: string) => Promise<T[]>
      minCharForSearch: number
    })
  | (UseSelectRestParams<T> & {
      type: 'multi'
      value?: T[]
      propsOptions?: T[]
      onChange?: (options: T[]) => void
      isSearchable: never
      loadOptions?: never
      minCharForSearch: never
    })

export const useSelect = <T extends DefaultOption>({
  disabled,
  onBlur,
  value,
  onChange,
  labelName,
  valueName,
  type,
  propsOptions,
  loadOptions,
  minCharForSearch,
  footer,
  readOnly,
}: UseSelectParams<T>) => {
  const [isOptionsOpened, setIsOptionsOpened] = useState(false)
  const [options, setOptions] = useState<T[] | undefined>(propsOptions)
  const [inputValue, setInputValue] = useState<string>('')
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  const loadVersion = useRef<string>('')

  useEffect(() => {
    setOptions(propsOptions)
  }, [propsOptions])

  useEffect(() => {
    setInputValue(getInputValueFromOption(value, labelName))
  }, [value, labelName])

  const hasPropsOptions = (options?.length ?? 0) > 0

  const valueSet = new Set(
    type === 'single'
      ? [value?.[valueName]].filter(Boolean)
      : value?.map((v) => v[valueName]) ?? [],
  )

  const _resetOptions = () => {
    setOptions(propsOptions)
  }

  const _setValueAfterBlur = () => {
    if (!value) {
      setInputValue('')
    } else {
      setInputValue(getInputValueFromOption(value, labelName))
    }
  }

  const handleClickInput = () => {
    if (isOptionsOpened) {
      setIsOptionsOpened(false)
    } else if (!disabled && !readOnly) {
      setIsOptionsOpened(hasPropsOptions || footer !== undefined)
    }
  }

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsOptionsOpened(false)

    // _resetFocusOptionIndex()
    _setValueAfterBlur()
    _resetOptions()

    onBlur?.(event)
  }

  const handleClickOption = (option: T, isCreated: boolean = false) => {
    if (option.disabled) return

    let newValue: T | T[]

    if (type === 'multi') {
      if (valueSet.has(option[valueName])) {
        newValue = (value || []).filter(
          (v) => v[valueName] !== option[valueName],
        )
        onChange?.(newValue)
      } else {
        newValue = [...(value || []), option]
        onChange?.(newValue)
      }
    } else {
      newValue = option
      onChange?.(option, isCreated)
      setIsOptionsOpened(false)
    }

    _resetOptions()
    // _resetFocusOptionIndex()
  }

  const handleInputChange = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target

    setInputValue(value)
    setIsOptionsOpened(hasPropsOptions)

    if (!loadOptions) {
      setOptions(
        filterOptions<T>({ value, labelName, options: propsOptions ?? [] }),
      )
    } else if (value.length >= minCharForSearch) {
      loadVersion.current = value
      setIsLoadingOptions(true)
      void loadOptions(value).then((options) => {
        if (value === loadVersion.current) {
          setOptions(options)
          setIsLoadingOptions(false)
          setIsOptionsOpened(true)
        }
      })
    } else {
      _resetOptions()
      setIsOptionsOpened(false)
    }
  }

  const handleCreateOption = () => {
    handleClickOption(
      {
        [labelName]: inputValue,
        [valueName]: inputValue,
      } as unknown as T,
      true,
    )
  }

  const handleClickClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setIsOptionsOpened(false)
    if (type === 'single') {
      onChange?.(null, false)
    } else {
      onChange?.([])
    }
    _resetOptions()
  }

  return {
    flags: { isOptionsOpened, isLoadingOptions },
    data: { inputValue, valueSet, options },
    actions: {
      handleClickInput,
      handleInputBlur,
      handleClickOption,
      handleClickClear,
      handleInputChange,
      handleCreateOption,
      onClose: () => setIsOptionsOpened(false),
    },
  }
}
